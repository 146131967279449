import React from 'react';
import { TextInput, SimpleForm, Create } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';

import { validateTitle, validateDescription, validateSection, validateContent } from '../../../util/ArticleValidation'
import { SectionSelectorInput } from '../../../util/SectionSelectorInput'

const CreateScrapedPage = props => {
  const classes = useStyles();

  return (
    <div className={classes.centerContainer}>
      <Typography variant="h6" className={classes.headerText}>
        Create a new audio snippet for social distribution or article for a podcast episode by filling in the form below.<br/>
        Type or copy-paste in the main article text.
      </Typography>
      <Create title="New article" {...props} className={classes.createForm}>
          <SimpleForm redirect="list">
              <TextInput source="title" multiline fullWidth validate={validateTitle}/>
              <TextInput source="description" multiline fullWidth validate={validateDescription}/>
              <TextInput source="author" fullWidth/>
              <SectionSelectorInput validate={validateSection}/>
              <RichTextInput label="Content" source="richContent" toolbar={false} multiline
                  validate={validateContent} variant={classes.richTextEditor}/>
          </SimpleForm>
      </Create>
    </div>);
}

const useStyles = makeStyles(theme => ({
  centerContainer: {
    margin: 'auto',
    minWidth: '400px',
    maxWidth: '700px',
    width: '100%'
  },
  headerText: {
    maxWidth: '600px'
  },
  spacer: {
    margin: 'auto'
  },
  createForm: {
    width: '100%',
    maxWidth: '600px',
    marginRight: 'auto'
  },
  richTextEditor: {
    minHeight: '300px',
    backgroundColor: 'rgba(0, 0, 0, 0.08) !important',

    "& .ql-editor": {
      backgroundColor: 'unset',
    }
  }
}));

export default CreateScrapedPage;
