import React, {useState, useEffect} from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useNotify } from 'react-admin';

import { infoBoxStyles } from './ReportStyles';

export const WidgetInfoBox = ({title, ...rest}) => {
  const classes = infoBoxStyles();
  const notify = useNotify();

  const [widgetInfo, setWidgetInfo] = useState();

  useEffect(() => {
      fetch('/backend/rest/report/widget-info')
        .then(response => {
          if (!response.ok) {
            notify("Could not get widget info!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          setWidgetInfo(json);
        });
  }, [notify]);


  return (
    <div {...rest}>
      <Paper className={classes.graphPaper2}>
        <Typography variant="h5" className={classes.headerText}>{title}</Typography>
        <Typography variant="h4" className={classes.doughnutNumber}>{widgetInfo ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(widgetInfo.totalPlaytimeSeconds / 60) : '-'}</Typography>
        <Typography variant="h4" className={classes.downloadsText}> Minutes played</Typography>
        <Typography variant="span" className={classes.viewsNumber}>{widgetInfo ? new Intl.NumberFormat('en-US').format(widgetInfo.numPages) : '-'}</Typography>
        <Typography variant="span" className={classes.viewsText}> unique pages</Typography><br/>
        <Typography variant="span" className={classes.viewsNumber}>{widgetInfo ? new Intl.NumberFormat('en-US').format(widgetInfo.numSessions) : '-'}</Typography>
        <Typography variant="span" className={classes.viewsText}> unique sessions</Typography>
      </Paper>
    </div>
  );
}

export default WidgetInfoBox;
