import React from 'react';

import { Typography, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '140%'
  },
  paragraph: {
    fontSize: '100%',
    margin: '15px 0px'
  },
  aside: {
    width: '300px',
    height: 'fit-content',
    margin: '20px',
    padding: '20px'
  }
}));

const HelpText = ({title, activeText, texts}) => {
  const classes = useStyles();

  var currentText = texts.get("default");
  if (activeText) {
    currentText = texts.get(activeText);
  }

  return (
      <Paper className={classes.aside}>
          { title ?
            <Typography className={classes.title} variant="h4" paragraph>
                {title}
            </Typography> : null }
          <div className={classes.paragraph}>
            { currentText?.map((paragraph, index) =>
              <Typography key={index} className={classes.paragraph} variant="body1">
                  {paragraph}
              </Typography>)
            }
          </div>
      </Paper>
  );
};

export default HelpText;