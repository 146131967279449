import React, { useState } from 'react';
import { Title } from 'react-admin';

import { makeStyles, Stepper, Step, StepLabel, Button, Paper } from '@material-ui/core';

import { CustomerDetailsStep } from './CustomerDetailsStep';
import { PaymentInput } from './PaymentInput';
import { SubscriptionOverview } from './SubscriptionOverview';
import { createCustomer } from './PaymentUtil';

import { mainContainerStyle } from '../../style/mainContainerStyle';

const useStyles = makeStyles(theme => ({
  stepContentContainer: {
    margin: '15px 0px'
  },
  stepContentPaper: {
    padding: '15px'
  },
  buttonContainer: {
    margin: '15px'
  }
}));

export const PaymentOnboarding = ({customerInfo, paymentConfig, setCustomerInfo, usage}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);

  const [customerDetailsError, setCustomerDetailsError] = useState("");

  const [subscription, setSubscription] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);

  const isValidEmail = (email) => {
    return email && email.match(/^[\w-.]+(\+[\w-.]+)*@([\w-]+\.)+[\w-]{2,4}$/);
  }

  const onCustomerDetailsChange = (customerInfo) => {
    if (customerInfo?.subscriptionType === 'enterprise') {
       setNextEnabled(true);
       return;
    }

    if (customerInfo?.customer?.name
        && isValidEmail(customerInfo?.customer?.email)
        && customerInfo?.subscriptionType) {
       setNextEnabled(true);
    } else {
       setNextEnabled(false);
    }
  }

  const subscriptionCompleteCallback = (result) => {
    setSubscription(result);
    setNextEnabled(true);
  }

  const advance = () => {
    setNextEnabled(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleNext = () => {
    if (customerInfo?.subscriptionType === 'enterprise') {
      window.location = 'mailto:info@audio-harvest.com';
      return;
    }

    switch (activeStep) {
      case 0:
        if (!isValidEmail(customerInfo.customer.email)) {
          setCustomerDetailsError('Please enter a valid email address!');
          return;
        } else if (!customerInfo.customer.name) {
          setCustomerDetailsError('Please fill in a business name');
          return;
        } else if (!customerInfo.subscriptionType) {
          setCustomerDetailsError('Please pick a subscription type!');
          return;
        }

        createCustomer(customerInfo, setCustomerInfo)
          .then(ret => advance())
          .catch((error) => {
            setCustomerDetailsError("Failed to create customer! Are you sure the VAT ID has the correct format?\nPlease try again later or contact support at support@audio-harvest.com" + (error ? error : ""));
          });
        return;
      case 1:
        if (!subscription) {
          return;
        }
        break;
      case 2:
        break;
      default:
        return null;
    }

    advance();
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Paper className={classes.stepContentPaper}>
                <CustomerDetailsStep onCustomerDetailsChange={onCustomerDetailsChange} customerDetailsError={customerDetailsError}
                  customerInfo={customerInfo} usage={usage}/>
               </Paper>;

      case 1:
        return <Paper className={classes.stepContentPaper}>
                  <PaymentInput subscriptionCompleteCallback={subscriptionCompleteCallback}
                      customerInfo={customerInfo} usage={usage}/>
               </Paper>;

      case 2:
        return <SubscriptionOverview customerInfo={customerInfo} subscription={subscription} usage={usage}/>
      default:
        return null
    }
  }

  return (
    <div className={mainContainerStyle().mainContainer}>
      <Title title="Subscription management" />
      <Stepper activeStep={activeStep}>
        <Step key={'email'}>
          <StepLabel>Email & subscription type</StepLabel>
        </Step>
        <Step key={'payment-details'}>
          <StepLabel>Payment details</StepLabel>
        </Step>
        <Step key={'view-subscription'}>
          <StepLabel>Subscription overview</StepLabel>
        </Step>
      </Stepper>
      <div className={classes.stepContentContainer}>
        { getStepContent() }
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={!nextEnabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
