import React from 'react';

import { makeStyles, Typography, Paper,
 Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2em',
    fontWeight: '600'
  },
  structureTitle: {
    margin: '0px 0px 20px 0px'
  },
  tableContainer: {
    maxWidth: '600px',
    margin: '0px 0px 20px 0px',

    '& th': {
      fontWeight: '600'
    }
  },
  paper: {
    marginTop: '20px',
    padding: '15px'
  },
  activeTier: {
    backgroundColor: '#28a69a59'
  },
  nextInvoiceContainer: {
    margin: '10px 0px',
    fontSize: '1.2em'
  },
  nextInvoiceAmount: {
    fontWeight: '600'
  }
}));

const getTierData = (tiers, currency, usage) => {
    var rows  = [];

    var start = 0, i = 0;
    var currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency });
    var curUsage = usage?.currentUsage;

    tiers.forEach(tier => {
        var activeTier = curUsage != null ? (curUsage >= start && (tier.up_to ? curUsage <= tier.up_to : true)) : false;
        rows.push([i++, start, tier.up_to ? tier.up_to : '∞', currencyFormatter.format(tier.flat_amount/100), activeTier]);
        start = tier.up_to + 1;
    });

    return rows;
}

const getNextInvoiceAmount = (tierData) => {
  return tierData?.filter(row => row[4])
            .map(row => row[3])[0];
}

const PaymentTierOverview = ({tiers, currency, usage}) => {
  const classes = useStyles();

  var tierData = getTierData(tiers, currency, usage);
  var nextInvoiceAmount = getNextInvoiceAmount(tierData);

  return (
    <div className={classes.tableContainer}>
      <div className={classes.structureTitle}>
        <Typography
            variant="h3"
            color="inherit"
            className={classes.title}
            id="structure">
          Payment tier structure
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            { tierData?.map(row => {
                      return (
                    <TableRow key={row[0]} className={classnames(row[4] ? classes.activeTier : null)}>
                      <TableCell align="left">From</TableCell>
                      <TableCell component="th" scope="row">{row[1]}</TableCell>
                      <TableCell align="left">until</TableCell>
                      <TableCell component="th" align="right">{row[2]}</TableCell>
                      <TableCell align="left">articles:</TableCell>
                      <TableCell component="th" align="right">{row[3]}</TableCell>
                    </TableRow>); })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.nextInvoiceContainer}>
        { nextInvoiceAmount
            ? <span>Your next invoice amount will be: <span className={classes.nextInvoiceAmount}>{nextInvoiceAmount}</span> .</span>
            : null
        }
      </div>
    </div>
  );
}

export default PaymentTierOverview;
