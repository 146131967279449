import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';

import { Typography, Paper, makeStyles,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

  mainContainer: {
    marginTop: '20px',
    width: 'auto',
    textAlign: 'center'
  },
  table: {
    display: 'inline-block',
    width: 'fit-content',
    minWidth: '500px'
  },
  title: {
    width: 'fit-content',
    minWidth: '500px',
    margin: '30px auto',
    padding: '5px 10px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '12px',
  },
  summaryrow: {
    fontStyle: 'italic',
    fontWeight: 'bold'
  },
  extraInfo: {
    textAlign: 'left',
    width: '500px',
    margin: '20px auto',
    fontSize: '12px',
    fontStyle: 'italic'
  }
}));

const createReport = (endPoint, title, styles = useStyles) => {

  return (
   (props) => {
      const classes = styles();
      const notify = useNotify();

      const [reportInfo, setReportInfo] = useState();

      useEffect(() => {
        const request = new Request('/backend/rest/' + endPoint + '/', {
            method: 'GET'
        });
        fetch(request)
          .then(response => {
              return response.ok ? response.json() : Promise.reject(response.statusText);
          })
          .then(setReportInfo)
          .catch(error => {
            notify('Failed to fetch report data! ' + (error?.message ? error?.message : ''), { type: 'error' });
            console.log(error);
          });
      }, [notify]);

      if (reportInfo && reportInfo.metadata && reportInfo.metadata.siteKey) {
        return (
          <div className={classes.mainContainer}>
            { title
                ? <Typography
                      variant="h4"
                      color="inherit"
                      className={classes.title}
                      id="summary-report-title">
                    {title}
                  </Typography> : null }
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{reportInfo.headers[0]}</TableCell>
                    { reportInfo.headers.slice(1).map(header => <TableCell key={header} align="right">{header}</TableCell>) }
                  </TableRow>
                </TableHead>
                <TableBody>
                  { reportInfo.rows.length > 0 ?
                   <TableRow key={reportInfo.rows[0][0]}>
                     <TableCell className={classes.summaryrow} component="th" scope="row">{reportInfo.rows[0][0]}</TableCell>
                     { reportInfo.rows[0].slice(1).map(value => <TableCell key={reportInfo.rows[0][0]} className={classes.summaryrow} align="right">{value}</TableCell>) }
                   </TableRow> : null
                  }
                  { reportInfo.rows.slice(1).map((values) =>
                                 <TableRow key={values[0]}>
                                   <TableCell className={classes.normalRow} component="th" scope="row">{values[0]}</TableCell>
                                   { values.slice(1).map((value, index) => <TableCell className={classes.normalRow} key={index} align="right">{value}</TableCell>) }
                                 </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.extraInfo} dangerouslySetInnerHTML={{__html:reportInfo.extraInfo}}/>
          </div>
        );
      } else {
        return (
          <div className={classes.mainContainer}>
            <Typography
                variant="h4"
                color="inherit"
                className={classes.title}
                id="summary-report-title">
              Loading...
            </Typography>
          </div>
        );
      }
  });
}

export default createReport;
