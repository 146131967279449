export const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request('/backend/login', {
            method: 'POST',
            body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
             }),
            redirect: 'error'
        });
        return fetch(request)
            .then(response => {
                return response.text();
            })
            .then(body => {
                try {
                    return JSON.parse(body);
                } catch {
                    throw Error(body);
                }
            })
            .then(userInfo => {
                sessionStorage.setItem('loggedIn', true);
                sessionStorage.setItem('roles', JSON.stringify(userInfo.roles));
                sessionStorage.setItem('permissions', JSON.stringify(userInfo.permissions));
                sessionStorage.setItem('siteKey', userInfo.siteKey);
                return userInfo.siteKey;
            })
            .then(siteKey => {
                fetch('/backend/rest/siteconfig/' + siteKey)
                    .then(response => {
                        if (!response.ok) {
                            console.log("Could not get site info!");
                            return Promise.reject();
                        }
                        return response.json();
                    })
                    .then(json => {
                        sessionStorage.setItem('trialExpired', json.trialExpired)
                    });
                return Promise.resolve()
            })
            .catch(error => {
                throw new Error("Failed to login! " + error.message);
            })
    },

    logout: params => {
        const request = new Request('/backend/logout', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                return response.ok ? Promise.resolve("") : Promise.reject(response.statusText);
            })
            .then(response => {
                sessionStorage.clear();
                return "/login";
            })
            .catch(error => {
                throw new Error("Failed to logout! " + error);
            });
    },

    checkAuth: () => {
        // Superficial approach
//        return sessionStorage.getItem('loggedIn') === 'true' ? Promise.resolve() : Promise.reject();

        // Thorough approach
        const request = new Request('/backend/checkLogin', {
            method: 'GET'
        });
        return fetch(request)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    console.log("Failed auth check, logging out... Response code: " + response.status);
                    sessionStorage.clear();
                    return Promise.reject();
                }
                return response.text().then(text => {
                    text === "OK" ? Promise.resolve() : Promise.reject();
                });
            })
    },

    checkError: (error) => {
/*
        console.log(error, error.status);
        const status = error.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
*/
        return Promise.resolve();
    },

    getPermissions: () => {
        const perms = sessionStorage.getItem('permissions');
        if (perms) {
            var permsList = JSON.parse(perms);
            return Promise.resolve(permsList);
        }

        const request = new Request('/backend/rest/user/info', {
            method: 'GET'
        });
        return fetch(request)
            .then(response => {
                return response.ok ? response.json() : Promise.reject(response.statusText);
            })
            .then(userInfo => {
                sessionStorage.setItem('roles', JSON.stringify(userInfo.roles));
                sessionStorage.setItem('permissions', JSON.stringify(userInfo.permissions));
                sessionStorage.setItem('siteKey', userInfo.siteKey);
                return userInfo.permissions;
            })
            .catch(error => {
              throw new Error("Failed to fetch permissions! " + error);
            });
    }
};

export const hasPermission = (permissions, perm) => {
    return permissions && permissions.includes(perm);
}

export const hasPermissionSessionStorage = (perm) => {
    var permissions = sessionStorage.getItem('permissions');
    return permissions && permissions.includes(perm);
}

export const hasRole = (role) => {
    return sessionStorage.getItem('roles') && sessionStorage.getItem('roles').includes(role);
}

