import React from 'react';
import { makeStyles } from '@material-ui/core';
import { hasPermissionSessionStorage } from '../../util/authProvider';

const useStyles = makeStyles(theme => ({
  audioPlayer: {
    height: '30px',
    width: '100%',
    outline: 'none'
  },
  audiofileInfo: {
    margin: '10px 0px',
    padding: '5px',

    '& p': {
      margin: '0px',
      fontStyle: 'italic',
      fontSize: '10px',
      textAlign: 'center'
    }
  }
}));

export const AudioFilePreview = ({record, source}) => {
  const classes = useStyles();

  if (record.audiofileUrl) {
     return (
     <div className={classes.audiofileInfo}>
       <audio controls preload="metadata"
         controlsList={hasPermissionSessionStorage('DownloadPodcasts') ? '' : 'nodownload'}
         className={classes.audioPlayer}>
           <source src={record.audiofileUrl + '?link=admin-ui'} type="audio/mpeg"/>
           Your browser does not support the audio element.
       </audio>
       <p>Audiofile generated on {new Intl.DateTimeFormat("en-GB", {
                                             dateStyle: 'short',
                                             timeStyle: 'medium'
                                          }).format(Date.parse(record.audiofileCreatedTime))}</p>
     </div>);
  } else {
    return null;
  }
}

