import React, { useState, useRef, Fragment } from 'react';
import { useNotify, ImageInput } from 'react-admin';

import { makeStyles } from '@material-ui/core';

export const AudioFileUploadInput = ({ record = {}, validate, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();

  const imageInputRef = useRef(null);

  const [previewUrl, setPreviewUrl] = useState();

  const onDropRejected = (e) => {
    notify("The file was not accepted! Make sure the file is a MPEG audio (mp3) or Advanced Audio Codec (aac,m4a) file and the filesize is maximum 1 gigabyte!", { type: 'warning' });
  };

  const onDrop = (newFiles, rejectedFiles, event) => {
    if (newFiles.length === 1) {
      setPreviewUrl(URL.createObjectURL(newFiles[0]));
    }
  };

  const getMimeType = (url) => {
    if (url.endsWith('.mp3')) {
      return "audio/mpeg";
    } else if (url.endsWith('.aac')) {
      return "audio/aac";
    } else if (url.endsWith('.m4a')) {
      return "audio/m4a";
    }
  }

  return (
    <div>
       { previewUrl
         ? <audio controls preload="metadata"
             controlsList={"nodownload"}
             className={classes.audioPlayer}>
               <source src={previewUrl} type={ getMimeType(previewUrl) }/>
               Your browser does not support the audio element.
           </audio>
         : null }
       <ImageInput className={classes.audioFileUpload} source="audioFileUpload" label="Upload audio file" accept="audio/mpeg,audio/aac,audio/m4a,.mp3,.aac,.m4a" maxSize={1000000000}
        placeholder={<p ref={imageInputRef}>Drop an audio to upload, or click to select it.<br/><br/>The audio file should be either MPEG audio (mp3) or Advanced Audio Codec (aac/m4a).</p>}
        options={{onDrop, onDropRejected}} validate={validate}>
        <Fragment/>
      </ImageInput>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  podcastLogoInputContainer: {
    display: 'flex'
  },
  audioFileUpload: {
    "& label span": {
      fontSize: '30px',
      fontWeight: '700'
    }
  },
  audioPlayer: {
    height: '30px',
    width: '100%',
    outline: 'none'
  },
}));