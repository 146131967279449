import React from 'react';
import { List, Datagrid, TextField, NumberField, Filter, TextInput, EditButton,
  TopToolbar, CreateButton} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { PodcastPanel } from './PodcastPanel';
import { PublishedIcons } from '../../../components/podcasts/PublishedIcons';
import { PodcastLink, PodcastImage, CategoryField } from '../../../components/podcasts/PodcastMisc';
import Empty from '../../../util/Empty';

import { mainContainerStyle } from '../../style/mainContainerStyle';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontStyle: 'italic',
  }
}));

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable={true} />
    </Filter>
);

const CustomEmpty = (props) => {
  const title = "Create podcast";
  const message = [
    <span>No podcasts exist! Do you want to create one?</span>
  ];

  return <Empty title={title} message={message} hasCreate={true}/>;
}

const ListActions = (props) => (
    <TopToolbar>
        <CreateButton label="Create new podcast"/>
    </TopToolbar>
);

const ListPodcasts = props => {
  const mainContainerClass = mainContainerStyle();
  const classes = useStyles();

  return (
    <div className={mainContainerClass.mainContainer}>
      <List title="Podcasts" bulkActionButtons={false}
          sort={{ field: 'created', order: 'DESC' }}
          filters={<PostFilter/>} exporter={false}
          empty={<CustomEmpty/>} actions={<ListActions/>}
          {...props}>
          <Datagrid rowClick={"expand"} expand={<PodcastPanel/>}>
              <PodcastImage label=""/>
              <TextField label="Title" className={classes.pageTitle} source="title"/>
              <EditButton label="Edit"/>
              <PodcastLink label="Podcast link" source="link"/>
              <NumberField label="Episodes" source="episodeCount" sortable={false}/>
              <CategoryField label="Categories" source="categories"/>
              <PublishedIcons label="Published"/>
          </Datagrid>
      </List>
    </div>);
}

export default ListPodcasts;