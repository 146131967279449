import React, {useState, useEffect} from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { defaults, Line } from 'react-chartjs-2';

export const LineGraph = ({title, endpoint, period, lastNum, ...rest}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [data, setData] = useState();

  useEffect(() => {
    var url = '/backend/rest/' + endpoint + '?lastNum=' + lastNum + (period ? '&period=' + period : '');
    const request = new Request(url, {
        method: 'GET'
    });
    fetch(request)
      .then(response => {
          return response.ok ? response.json() : Promise.reject(response.statusText);
      })
      .then(json => {
        json.datasets.forEach((dataset, index) => {
          dataset.backgroundColor = backgroundColors[index];
          dataset.borderColor = borderColors[index];
        });
        setData(json);
      })
      .catch(error => {
        notify('Failed to fetch report data! ' + (error?.message ? error?.message : ''), { type: 'error' });
        console.log(error);
      });
  }, [notify, endpoint, period, lastNum]);

  return (
    <div {...rest}>
      <Paper className={classes.graphPaper}>
        <Typography variant="h5" className={classes.headerText}>{title}</Typography>
        <Line data={data} options={options} />
      </Paper>
    </div>
  );
}

defaults.font.family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
defaults.plugins.legend.position = 'bottom';
defaults.elements.line.cubicInterpolationMode = 'default';
defaults.elements.line.tension = '0.4';
const options = {
  plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 16,
              }
          },
      },
  },
  elements: {
    point: {
      pointRadius: 4
    },
    line: {
      borderWidth: 2
    }
  },
  scales: {
    x: {
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: false,
        drawTicks: true,
        borderWidth: 2,
        borderColor: 'black',
        tickWidth: 2,
        tickColor: 'black'
      }
    },
    y: {
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [4, 4],
      },
      beginAtZero: true
    },
  },
};

const useStyles = makeStyles(theme => ({
  graphPaper: {
    margin: '10px',
    padding: '10px',
  },
  headerText: {
    textAlign: 'center'
  }
}));

const backgroundColors = [
  "#0FD1B0",
  "#737474",
  "#04BA9B",
  "#00483C",
];

const borderColors = [
  "#0FD1B0",
  "#737474",
  "#04BA9B",
  "#00483C",
];

export default LineGraph;
