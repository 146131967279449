import React, { useState, Fragment } from 'react';
import { useNotify, TextInput, Edit, SimpleForm, DateTimeInput,
        Toolbar, SaveButton, DeleteButton, UrlField, Confirm, Labeled } from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles, Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import RestorePageIcon from '@material-ui/icons/RestorePage';

import { validateTitle, validateDescription, validateSection } from '../../../util/ArticleValidation'
import { AudioFilePreview } from '../../../components/scraped_page/AudioFilePreview';
import { SectionSelectorInput } from '../../../util/SectionSelectorInput'

import { updateArticle, compensateTimezone } from '../scraped_page/ScrapedPageUtil';

export const EditAudioPage = props => {
  const classes = useStyles();

  const ConditionalUrlField = ({record, source, label, ...props}) => {
    if (record[source].startsWith('manual')) {
      return null;
    }

    return (
      <Labeled label={label}>
        <UrlField target="_blank" record={record} source={source} {...props}/>
      </Labeled>
     );
  }

  return (
    <div className={classes.centerContainer}>
      <Edit mutationMode="pessimistic" title="Captured page" {...props} className={classes.richTextEditor}>
          <SimpleForm toolbar={<EditScrapedPageActions/>}>
              <TextInput source="title" multiline fullWidth validate={validateTitle}/>
              <TextInput source="description" multiline fullWidth validate={validateDescription}/>
              <TextInput source="author" fullWidth/>
              <DateTimeInput label="Publish Time" source="publishTime" fullWidth
                  format={compensateTimezone} parse={compensateTimezone}/>
              <SectionSelectorInput validate={validateSection}/>
              <ConditionalUrlField label="URL" source="url" className={classes.urlField}/>
              <AudioFilePreview/>
          </SimpleForm>
      </Edit>
    </div>);
}

const EditScrapedPageActions = props => {
  const classes = useStyles();

  return (
    <Toolbar {...props} >
        <SaveButton />
        <div className={classes.spacer}/>
        <RefreshArticleButton {...props}/>
        <DeleteButton />
    </Toolbar>);
}

const RefreshArticleButton = ({record, ...props}) => {
  const notify = useNotify();
  const form = useForm();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  if (!record.url || record.url.startsWith("manual:")) {
    return null;
  }

  return (
      <Fragment>
        <Confirm
            isOpen={confirmDeleteOpen}
            title="Refresh article"
            content={<div>
                      <p>If you press confirm below, we will try to fetch the page and update the article contents based on the latest version of the page.
                      The updated content will replace the current article content.</p>
                      <p>However, if you don't want to save the updated article, you can just navigate away from this page or refresh.
                      The updated article contents are only saved when you press the save button!</p></div>
                    }
            confirm="Refresh page"
            confirmColor="primary"
            ConfirmIcon={RestorePageIcon}
            CancelIcon={CancelIcon}
            cancel="Cancel"
            onConfirm={() => { setConfirmDeleteOpen(false); updateArticle(form, record, notify); }}
            onClose={() => { setConfirmDeleteOpen(false); }}
        />
        <Button color="primary" onClick={ () => setConfirmDeleteOpen(true) }>Refresh article from site</Button>
      </Fragment>);
}

const useStyles = makeStyles(theme => ({
  centerContainer: {
    margin: 'auto',
    minWidth: '400px',
    maxWidth: '700px',
    width: '100%'
  },
  spacer: {
    margin: 'auto'
  },
  urlField: {
    overflowWrap: 'anywhere'
  }
}));
