export const continueButtonStyle = {
       color: 'white',
       background: '#0FD1B0',
       position: "relative",
       fontWeight: 'bold',
       fontFamily: "Helvetica, sans-serif",
       fontSize: '18px',
       overflow: "hidden",
       marginTop: '30px',
       padding: '10px 20px',
       border: "1.4px solid",
       borderRadius: "30px",
       letterSpacing: "3px",
       "&:hover": {
         background: '#0FD1B0'
       }
   };