import React from 'react';
import { Title } from 'react-admin';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: '20px',
    width: 'auto',
    maxWidth: '1000px',
    margin: 'auto',
    textAlign: 'left',
    fontSize: '18px'
  },
}));

const AudioPlayerSetup = (endPoint, title) => {
  const classes = useStyles();

  return (
    <>
      <Title title="On-site player setup" />
      <div className={classes.mainContainer} dangerouslySetInnerHTML={{ __html: html }}/>
    </>
  );
}

export default AudioPlayerSetup;

const html = `
      <style>
        .c11 {
          font-family: 'courier'
        }

        .c11 p {
          margin: 0px
        }
      </style>
      <h1 class="c1" id="h.jk6oohovi4sw">
        <span class="c33 c35">AudioHarvest Widget installation guide</span>
      </h1>
      <p class="c5">
        <span class="c3">In order to add the AudioHarvest audio player widget to your web pages, a small piece of code needs to be added to your website. This code will make sure the AudioHarvest audio player is displayed when the audio content for your page is available.</span>
      </p>
      <p class="c5">
        <span>If you have any questions or need support in setting up the AudioHarvest widget, do not hesitate to reach out to </span>
        <span class="c34"><a class="c8" href="mailto:support@audio-harvest.com">support@audio-harvest.com</a></span>
      </p>
      <h2 class="c2" id="h.f0l5nrjtm2t1">
        <span class="c27">Features</span>
      </h2>
      <ul class="c32 lst-kix_r0htw39akdq3-0 start">
        <li class="c5 c21 li-bullet-0">
          <span class="c14 c15">The widget will remain hidden if there is no audio file available</span>
        </li>
        <li class="c5 c21 li-bullet-0">
          <span class="c14">The widget can be styled (size, placement, colors&hellip;) to match your website design</span>
          <span class="c15 c14">&nbsp;using standard CSS style rules</span>
        </li>
        <li class="c5 c21 li-bullet-0">
          <span class="c15 c14">When used in a Wordpress custom HTML element, the widget will hide this Wordpress component if there is no audio content available</span>
        </li>
        <li class="c5 c21 li-bullet-0">
          <span class="c15 c14">By using a custom CSS class, a HTML element that contains the AudioHarvest widget can be marked to be hidden when the audio file is not available</span>
        </li>
      </ul>
      <h2 class="c2" id="h.3oxdclp0ns8d">
        <span class="c27">Installation</span>
      </h2>
      <p class="c5">
        <span class="c3">How the widget is integrated into your site depends on what kind of Content Management System (CMS) you are using (for example Wordpress). There are three different options to integrate the audio player on your website:</span>
        <ul>
          <li><a href="#wordpress-plugin">AudioHarvest Wordpress plugin (preferred)</a></li>
          <li><a href="#wordpress-generic">Generic setup in WordPress</a></li>
          <li><a href="#plugin-generic">Generic instructions (all sites)</a></li>
        </ul>
      </p>
      <h3 class="c2" id="h.qgn7qazb3pak">
        <span id="wordpress-plugin" class="c27">AudioHarvest WordPress plugin</span>
      </h3>
      <p class="c5">
        <span class="c3">By using our WordPress plugin, you can add the AudioHarvest player to your blog posts and articles without having to write any code. Simply install the plugin and the AudioHarvest player will appear for all articles you have published on your AudioHarvest account.</span>
        <span class="c3"></span>
      </p>
      <h4>Installation instructions</h4>
      <p>
        <ul>
          <li>Download the plugin by clicking <a href="https://www.audio-harvest.com/wp-content/uploads/2022/02/audioharvest-wp-plugin-1.0.0.zip">this link</a></li>
          <li>Login to your WordPress site and go to the <i>“Plugins > Add new”</i> page in the management dashboard.
          <img src="https://www.audio-harvest.com/wp-content/uploads/2022/02/image-3.png"
              style="width: 100%; max-width: 1000px; display: block; margin: 20px auto;"/></li>
          <li>Click <i>“Upload plugin”</i> and select the AudioHarvest plugin zip file and click upload</i></li>
          <li>If you completed the above steps, you should see the AudioHarvest plugin listed there:
              <img src="https://www.audio-harvest.com/wp-content/uploads/2022/02/image-2048x145.png"
                            style="width: 100%; max-width: 1000px; display: block; margin: 20px auto;"/></li>
          <li>Click the “Activate” link on the plugin entry</li>
          <li>Now you will find a page named “AudioHarvest Settings” under the Tools section in the WordPress dashboard.
              <img src="https://www.audio-harvest.com/wp-content/uploads/2022/02/image-1.png"
                                          style="width: 200px; display: block; margin: 20px auto;"/></li>
        </ul>
      </p>
      <p>The plugin is now installed and the AudioHarvest player will appear on your site pages if the article is published in your AudioHarvest account.</p>
      <p>For more information and information on how to configure the plugin, go to <a href="https://www.audio-harvest.com/wordpress-plugin/">the AudioHarvest Wordpress page on our website</a>.</p>
      <h3 class="c2" id="h.qgn7qazb3pak">
        <span id="wordpress-generic" class="c27">Generic setup in WordPress</span>
      </h3>
      <p class="c5">
        <span class="c3">When using WordPress, adding the widget is easier, but also highly dependent on which theme you are using.</span>
      </p>
      <p class="c5">
        <span>In general it is probably best to create a </span>
        <span class="c14">Custom HTML</span><span>&nbsp;widget, and add it to (a) certain section(s) of your website template. In the following example, we are using the popular </span>
        <span class="c14">Twenty Seventeen</span><span class="c3">&nbsp;WordPress template.</span>
      </p>
      <p class="c5 c6">
        <span class="c3"></span>
      </p>
      <p class="c5">
        <span>Login to the administration panel of your WordPress website, and navigate to the </span>
        <span class="c14">Appearance &gt; Widgets</span><span>&nbsp;page. You will see a widget type named </span>
        <span class="c14">Custom HTML</span><span>&nbsp;under the </span><span
          class="c14">Available Widgets</span><span class="c3">&nbsp;section.</span></p>
      <h3 class="c38" id="h.ss8enjmeiq56">
          <img
          alt="" src="/onboarding/widget/images/image3.png"
          style="width: 100%; max-width: 1000px; display: block; margin: auto;"
          title=""/>
      </h3>
      <p class="c5"><span>Click on the </span><span class="c14">Custom HTML</span><span>&nbsp;widget and add it to the website section where you want the AudioHarvest widget to appear. In the above example, there is a </span><span
          class="c14">Blog Sidebar</span><span class="c3">&nbsp;section containing a number of WordPress widgets.</span>
      </p>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5">
          <img
          alt="" src="/onboarding/widget/images/image4.png"
          style="width: 100%; max-width: 500px; display: block; margin: auto;"
          title=""/>
      </p>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5"><span>Once the </span><span class="c14">Custom HTML </span><span>widget is added to the</span><span>&nbsp;section</span><span>&nbsp;(</span><span
          class="c14">Blog Sidebar </span><span class="c3">in our example), drag the widget in the position where you want it to appear (relative to other existing widgets), and paste the following HTML code in the widget:</span>
      </p>
      <p class="c5 c6"><span class="c12"></span></p><a
          id="t.d5121b2b1703b42e3bb57ab14d6c17f917b7c5d3"></a><a id="t.2"></a>
      <table class="c11">
        <tbody>
        <tr class="c16">
          <td class="c13" colspan="1" rowspan="1"><p class="c7"><span class="c10">&lt;div </span><span
              class="c19">id</span><span class="c18">=&quot;audioharvest-widget&quot; </span><span
              class="c20 c22">wordpress</span><span class="c0">&gt;</span></p>
            <p class="c7"><span class="c10">&nbsp;&lt;script </span><span class="c19">src</span><span
                class="c18">=&quot;</span><span class="c17"><a class="c8"
                                                               href="https://www.google.com/url?q=https://static.audio-harvest.com/widget/v3/widget.js&amp;sa=D&amp;source=editors&amp;ust=1625529745643000&amp;usg=AOvVaw13yGEN-pIwpqCSCuzJPW_V">https://static.audio-harvest.com/widget/v3/widget.js</a></span><span
                class="c18">&quot;</span><span class="c0">&gt;</span></p>
            <p class="c7"><span class="c0">&nbsp;&lt;/script&gt;</span></p>
            <p class="c7"><span class="c0">&lt;/div&gt;</span></p></td>
        </tr>
        </tbody>
      </table>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5"><span class="c3">Press save to finish adding the new widget to the template.</span>
      </p>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5 c6"><span class="c3"></span></p>
      <hr style="page-break-before:always;display:none;"/>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5">
          <img
          alt="" src="/onboarding/widget/images/image2.png"
          style="width: 100%; max-width: 500px; display: block; margin: auto;"
          title=""/>
      </p>
      <p class="c5"><span class="c3">When opening an article, you should see the AudioHarvest widget is now part of the page.</span>
      </p>
      <p class="c5"><span class="c14">(Make sure your AudioHarvest</span><span class="c14">&nbsp;account is set up correctly in</span><span
          class="c15 c14">&nbsp;order to make sure that the audiofile for the page is available! Otherwise the widget may be part of the page, but hidden because the audio file cannot be loaded!)</span>
      </p>
      <p class="c5 c6"><span class="c3"></span></p>
      <h3 class="c25" id="h.p1edwkfwp7g5"><span class="c4"></span></h3>
      <h3 class="c25" id="h.uo6zrcgzcbgu"><span class="c4"></span></h3>
      <h3 class="c25" id="h.g88cakhxdqg7"><span class="c4"></span></h3>
      <h3 class="c25" id="h.h088xfz8cayb"><span class="c4"></span></h3>
      <h3 class="c25" id="h.13m34945eabd"><span class="c4"></span></h3>
      <h3 class="c38" id="h.vbzis9li3st5"><span class="c4">Adding the AudioHarvest Widget on top of the article</span>
      </h3>
      <p class="c5"><span>By default, the AudioPlayer widget will try to insert the player on top of the article content (see image below).</span>
      </p>
      <p class="c9">
          <img
          alt="" src="/onboarding/widget/images/image1.png"
          style="width: 100%; max-width: 1000px; display: block; margin: auto;"
          title=""/>
      </p>
      <p class="c5 c6"><span class="c3"></span></p>
      <p class="c5"><span>If for some reason you prefer that the AudioHarvest player is displayed in the Wordpress widget itself, just remove the </span><span
          class="c20 c39">wordpress </span><span class="c3">attribute that is highlighted in red in the code above. The widget will then not try to insert itself above the article content, but display itself as part of the Wordpress widget.</span>
      </p>
      <p class="c9">
          <img
          alt="" src="/onboarding/widget/images/image5.png"
          style="width: 100%; max-width: 1000px; display: block; margin: auto;"
          title=""/>
      </p>
      <p class="c6 c9"><span class="c3"></span></p>
      <p class="c5"><span>Please do not hesitate to email us if you have any questions or would like to discuss the installation process. Reach out at </span><span
          class="c34"><a class="c8" href="mailto:support@audio-harvest.com">support@audio-harvest.com</a></span><span>&nbsp;.</span>
      </p>
      <div>
      <h3 class="c38" id="h.gg9vnpfbv6r8">
        <span id="plugin-generic" class="c4">Generic setup</span>
      </h3>
      <p class="c5">
        <span class="c3">Insert the following piece of code on the pages where you want the AudioHarvest widget to appear:</span>
      </p>
      <table class="c11">
        <tbody>
        <tr class="c16">
          <td class="c13" colspan="1" rowspan="1"><p class="c7"><span class="c10">&lt;div </span><span
              class="c19">id</span><span class="c18">=&quot;audioharvest-widget&quot;</span><span
              class="c0">&gt;</span></p>
            <p class="c7"><span class="c10">&nbsp;&lt;script </span><span class="c19">src</span><span
                class="c18">=&quot;</span><span class="c17"><a class="c8"
                                                               href="https://www.google.com/url?q=https://static.audio-harvest.com/widget/v3/widget.js&amp;sa=D&amp;source=editors&amp;ust=1625529745633000&amp;usg=AOvVaw3-NbPb913FLbPjyvRbs1ur">https://static.audio-harvest.com/widget/v3/widget.js</a></span><span
                class="c18">&quot;</span><span class="c0">&gt;</span></p>
            <p class="c7"><span class="c0">&nbsp;&lt;/script&gt;</span></p>
            <p class="c7"><span class="c10">&lt;/div&gt;</span></p></td>
        </tr>
        </tbody>
      </table>
      <p class="c5">
        <span>The AudioHarvest widget will dynamically load the widget content in the </span>
        <span class="c14">audioharvest-widget &lt;div&gt;</span><span class="c3">&nbsp;element if there is audio content to play. If there is no audio content, the widget will not load.</span>
      </p>
      <p class="c5">
        <span class="c3">Typically, you will be using some kind of website theme or template. In that case it should be possible to add this code to the article template, making the AudioHarvest widget appear on all article pages. Alternatively you can add the widget to each article where you want the widget to appear.</span>
      </p>
      <hr style="page-break-before:always;display:none;"/>
      <h4 class="c31" id="h.vwsfo1hk5l9n">
        <span class="c29">Adding additional styling</span>
      </h4>
      <p class="c5">
        <span class="c3">It is very likely that you will need to add some custom CSS styling to make the widget fit in the design of your webpage. There are several ways to do this:<br/></span>
      </p>
      <ul class="c32 lst-kix_yfgiw4ulbsj6-1 start">
        <li class="c5 c24 li-bullet-0"><span>Add or modify the CSS properties in the </span><span
            class="c14">style</span><span>&nbsp;attribute of the </span><span class="c14">audioharvest-widget </span><span
            class="c20">&lt;div&gt;</span><span class="c14">&nbsp;</span><span
            class="c3">element<br/></span></li>
        <li class="c5 c24 li-bullet-0"><span>Add a </span><span
            class="c20">&lt;style scoped&gt;</span><span>&nbsp;element containing CSS rules before the </span><span
            class="c20">&lt;div&gt; </span><span>containing the AudioHarvest widget, applying style rules to the elements with the </span><span
            class="c14">#audioharvest-widget</span><span>&nbsp;and/or </span><span class="c14">#audioharvest-player </span><span
            class="c3">identifiers.<br/></span></li>
        <li class="c5 c24 li-bullet-0"><span>Add some CSS rules to a CSS stylesheet that is used to style the current page. The </span><span
            class="c14">#audioharvest-widget</span><span>&nbsp;and/or </span><span class="c14">#audioharvest-player</span><span
            class="c3">&nbsp;identifiers are the identifiers you want to target with these custom CSS rules.</span>
        </li>
      </ul>
      <h4 class="c31" id="h.onti9g4lawi1">
        <span>Hiding custom elements wrapping the widget</span>
      </h4>
      <p class="c5">
        <span>By default, only the audio player will be hidden if an audiofile is not available. You can however make another element disappear by assigning it the CSS class </span>
        <span class="c14">.audioharvest-container</span><span class="c3">&nbsp;(for example when the AudioHarvest widget is placed in some page element that should also be hidden when the audiofile is not available).</span>
      </p>
      <p class="c5">
        <span>In the following example, the </span><span class="c20">&lt;div&gt;</span><span>&nbsp;element wrapping the AudioHarvest widget and all its contents will be hidden as long as the audiofile is not loaded (including the </span>
        <span class="c14">&quot;Audio player&quot;</span><span class="c3">&nbsp;title).</span>
      </p>
      <table class="c11">
        <tbody>
        <tr class="c16">
          <td class="c13" colspan="1" rowspan="1"><p class="c7"><span class="c10">&lt;div </span><span
              class="c19">class</span><span class="c18">=&quot;audioharvest-container&quot;</span><span
              class="c10">&gt; </span><span
              class="c23 c20 c33">&lt;!-- This element will be hidden --&gt;</span></p>
            <p class="c7"><span class="c20 c23">&nbsp;</span><span
                class="c10">&lt;p&gt;&lt;h1&gt;</span><span class="c20 c30">Audio player</span><span
                class="c0">&lt;/h1&gt;&lt;p&gt;</span></p>
            <p class="c7"><span class="c10">&nbsp;&lt;div </span><span class="c19">id</span><span
                class="c18">=&quot;audioharvest-widget&quot;</span><span class="c0">&gt;</span></p>
            <p class="c7"><span class="c10">&nbsp; &lt;script </span><span class="c19">src</span><span
                class="c18">=&quot;</span><span class="c17"><a class="c8"
                                                               href="https://www.google.com/url?q=https://static.audio-harvest.com/widget/v3/widget.js&amp;sa=D&amp;source=editors&amp;ust=1625529745639000&amp;usg=AOvVaw3rbqfiOCRTl_6jDBgWXSxd">https://static.audio-harvest.com/widget/v3/widget.js</a></span><span
                class="c18">&quot;</span><span class="c0">&gt;</span></p>
            <p class="c7"><span class="c0">&nbsp; &lt;/script&gt;</span></p>
            <p class="c7"><span class="c0">&nbsp;&lt;/div&gt;</span></p>
            <p class="c7"><span class="c10">&lt;/div&gt;</span></p></td>
        </tr>
        </tbody>
      </table>
    </div>
`;