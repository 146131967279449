import React, { useRef } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles, Button } from '@material-ui/core';

import { useDropzone } from 'react-dropzone';

import { continueButtonStyle } from '../../style/ContinueButtonStyle.js'

export const LogoInputButton = ({type, podcastId, episodeGuid, refreshHook}) => {
  const classes = useStyles();
  const notify = useNotify();
  const imageInputRef = useRef(null);

  const onDropAccepted = (files) => {
    let formData = new FormData();
    formData.append("imageUpload", files[0]);
    formData.append("podcastId", podcastId);
    formData.append("episodeGuid", episodeGuid);
    formData.append("type", type);

    fetch('/backend/rest/podcast/image', {method: "POST", body: formData})
      .then(response => {
        notify("The logo was updated!");
        refreshHook();
      });
  }

  const onDropRejected = (e) => {
    notify("The file was not accepted! Make sure the file is a JPEG or PNG file and the filesize is maximum 5 megabyte!", { type: 'warning' });
  };

  const {getRootProps, getInputProps} = useDropzone({
      onDropRejected,
      onDropAccepted,
      accept: [
       'image/png': [],
       'image/jpeg': [],
      ],
      maxFiles: 1,
      maxSize: 5000000
  })

  let text = "Upload Podcast Logo";
  if (type === 'episodeDefaultLogo') {
    text = 'Upload Logo All Episodes';
  } else if (type === 'episodeLogo') {
    text = 'Upload Logo By Episode';
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button ref={imageInputRef} variant="contained" className={classes.imageUploadButton}>
        {text}
      </Button>
    </div>
  );

}

const useStyles = makeStyles(theme => ({
  imageUploadButton: {
    ...continueButtonStyle,
    margin: '10px',
    marginTop: '0px',
    padding: '10px',
    width: '150px',
    fontSize: '15px',
    letterSpacing: '0px',
  }
}));

