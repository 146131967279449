import React, { useState, useEffect } from 'react';
import { useNotify, Loading } from 'react-admin';
import { makeStyles, Paper } from '@material-ui/core';

import { hasPermissionSessionStorage } from '../../../util/authProvider';

import { LogoInputButton } from './LogoInputButton';

import classnames from 'classnames';

export const PodcastPreview = ({podcastId}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [podcast, setPodcast] = useState();
  const [version, setVersion] = useState(1);

  useEffect(() => {
    if (podcastId && podcastId > 0) {
      fetch(`/backend/rest/podcast/${podcastId}`)
        .then(response => {
          if (!response.ok) {
            notify("Could not get podcast!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          setPodcast(json);
        });
    }
  }, [notify, podcastId, version]);

  const refreshHook = () => {
    setVersion(version + 1);
  }

  if (!podcast) {
    return <Loading />;
  }

  return (
    <Paper className={classes.podcastPreviewPaper}>
      <div className={classes.podcastHeaderLogoContainer}>
        <div className={classes.podcastHeader}>
          <div className={classes.imageAndDescriptionRight}>
            <img alt="Podcast logo" className={classes.podcastImage} src={podcast.imageUrl ? podcast.imageUrl : 'https://static.audio-harvest.com/ah-logo-lightgreen.png' }/>
            <div className={classes.podcastHeaderRight}>
              <div className={classes.podcastTitle}>
                <span>{podcast.title}</span>
                <a href={'https://podcasts.audio-harvest.com/' + podcast.link + '?html'}>
                  <img alt="RSS logo" className={classes.rssImage} src="https://static.audio-harvest.com/rss-logo.png"/>
                </a>
              </div>
              <div className={classes.podcastDescriptionRight}>{podcast.description}</div>
              <div className={classes.headerShareContainer}>
                <div className={classes.spacer}/>
                { podcast.spotifyUrl != null && podcast.spotifyUrl.length > 10
                                      ? <div><img className={classes.shareButton} src="assets/spotify-share-button.png"
                                        alt="Spotify share button for podcast"
                                        onClick={() => {
                                          navigator.clipboard.writeText(podcast.spotifyUrl);
                                          notify("Link copied to clipboard!");
                                        }}/></div>
                                      : null }
              </div>
            </div>
          </div>
          <div className={classes.podcastDescriptionBottom}>{podcast.description}</div>
        </div>
        <div className={classes.headerLogoInputContainer}>
          <LogoInputButton type="podcastLogo" podcastId={podcast.id} refreshHook={refreshHook}/>
          <LogoInputButton type="episodeDefaultLogo" podcastId={podcast.id} refreshHook={refreshHook}/>
        </div>
      </div>
      <div className={classes.audioharvestBanner}>
        <a href={'https://www.audio-harvest.com/?utm_source=audioharvest&utm_medium=podcast_html&utm_term=' + podcast.link}>Powered by AudioHarvest</a>
      </div>
      {
        !podcast.episodeInfos || podcast.episodeInfos.length === 0
           ? <div className={classnames(classes.episode, classes.noEpisodes)}>No episodes yet!</div>
           : podcast.episodeInfos.map((episode, index) =>
                <div key={index} className={classes.episodeLogoContainer}>
                  <div className={classes.episode}>
                    <div className={classes.episodeHeader}>
                      <img alt="Episode logo" className={classes.episodeImage} src={episode.imageUrl ? episode.imageUrl : (podcast.episodeDefaultImageUrl ? podcast.episodeDefaultImageUrl : (podcast.imageUrl ? podcast.imageUrl : 'https://static.audio-harvest.com/ah-logo-lightgreen.png')) }/>
                      <div className={classes.episodeTitle}>
                        { podcast.prefixSection && episode.section
                               ? '[' + episode.section + '] ' + episode.title
                               : episode.title}
                      </div>
                    </div>
                    <div className={classes.episodeDescription}>
                      <p>{episode.description}</p>
                      <p className={classes.articleLinks}>
                        <a href={episode.articleUrl}>Original article</a> | <a href="https://www.audio-harvest.com">Powered by AudioHarvest</a>
                      </p>
                    </div>
                    <audio className={classes.episodePlayer} controls
                           controlsList={hasPermissionSessionStorage('DownloadPodcasts') ? '' : 'nodownload'}
                           preload="metadata"
                           src={episode.enclosure+'?link='+podcast.link+'-html'}>
                      Your browser does not support the audio element.
                    </audio>
                    <div className={classes.episodeFooter}>
                      <div className={classes.episodeTime}>{new Date(Date.parse(episode.publicationDate)).toString()}</div>
                      <div className={classes.spacer}/>
                      { episode.spotifyUrl != null && episode.spotifyUrl.length > 10
                          ? <div><img className={classes.shareButton} src="assets/spotify-share-button.png"
                                    alt="Spotify share button for episode"
                                  onClick={() => {
                                    navigator.clipboard.writeText(episode.spotifyUrl);
                                    notify("Link copied to clipboard!");
                                  }}/>
                            </div>
                          : null }
                    </div>
                  </div>
                  <div className={classes.episodeLogoInputContainer}>
                    <LogoInputButton type="episodeLogo" podcastId={podcast.id} episodeGuid={episode.pageKey} refreshHook={refreshHook}/>
                  </div>
                </div>
           )
      }
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  podcastPreviewPaper: {
      fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: '300',
      fontSize: '20px',

      maxWidth: '800px',
      margin: 'auto',
      padding: '10px'
  },

  podcastHeaderLogoContainer: {
    margin: '20px 230px 10px 20px',
    width: '1000px',
    display: 'flex',
  },

  headerLogoInputContainer: {
    width: '200px',
    marginLeft: '35px',
    textAlign: 'left'
  },

  podcastHeader: {
    backgroundColor: '#0FD1B0',
    padding: '10px',
    color: 'white',
    borderRadius: '10px',
    textAlign: 'left',
    display: 'block',
    width: 'calc(100% - 280px)'
  },

  podcastHeaderRight: {
    width: '100%'
  },

  headerShareContainer: {
    display: 'flex'
  },

  imageAndDescriptionRight: {
    display: 'flex',
  },

  podcastTitle: {
    margin: '10px',
    fontWeight: '700',
    fontSize: '180%',
  },
  podcastDescriptionRight: {
    margin: '10px',
    display: 'block',
    width: '100%'
  },
  podcastDescriptionBottom: {
    margin: '10px',
    display: 'none',
  },
  podcastImage: {
    margin: '10px',
    width: '100px',
    height: '100px',
    borderWidth: '0px',
    borderRadius: '5px',
  },

  rssImage: {
    height: '25px',
    margin: '0px 5px',
  },

  episodeLogoContainer: {
    width: '1000px',
    margin: '10px 230px 20px 20px',
    display: 'flex',
  },

  episodeLogoInputContainer: {
    width: '200px',
    marginLeft: '35px',
    textAlign: 'left'
  },

  episode: {
    padding: '10px',
    borderStyle: 'solid',
    borderWidth: '0px',
    borderRadius: '10px',
    backgroundColor: '#f0f3f4',
    textAlign: 'left',
    width: 'calc(100% - 280px)'
  },

  episodeHeader: {
    display: 'flex',
  },

  episodeImage: {
    margin: '0px 10px',
    width: '80px',
    height: '80px',
    borderWidth: '0px',
    borderRadius: '5px',
  },

  episodeTitle: {
      fontWeight: '700',
      fontSize: '140%',
      margin: '10px 10px 10px 10px',
  },

  episodeDescription: {
      margin: '0px 10px 0px 10px'
  },

  episodeTime: {
      fontStyle: 'italic',
      fontSize: '60%',
      margin: '0px 10px 5px 10px',
      paddingTop: '20px'
  },

  episodePlayer: {
      margin: '10px 5px 5px 5px',
      width: '98%',
      height: '30px',
      outline: 'none',
  },

  episodeFooter: {
    display: 'flex',
  },

  spacer: {
    flexGrow: '1'
  },

  shareButton: {
      width: '100px',
      borderRadius: '10px',
      marginRight: '20px'
  },

  articleLinks: {
      color: '#aaaaaa',
      textDecoration: 'none',
      fontSize: '80%',

      "& a": {
        color: '#aaaaaa',
        textDecoration: 'none',
        fontSize: '80%',
      },
      
      "& a:hover": {
        textDecoration: 'underline',
      }
  },
  
  audioharvestBanner: {
      textAlign: 'center',

      "& a": {
        textDecoration: 'none',
        color: 'black',
        fontSize: '120%',
        fontWeight: '500',
        letterSpacing: '2px',
        transition: 'letter-spacing 400ms,background-color 400ms',
        borderRadius: '10px',
        padding: '3px 10px',
      },
      
      "& a:hover": {
        boxShadow: '0px 0px 5px #eeeeee',
        textDecoration: 'none',
        letterSpacing: '3px',
        backgroundColor: '#eeeeee'
      }
  },

  noEpisodes: {
    textAlign: 'center',
    margin: '10px 20px 20px 20px',
    width: 'calc(100% - 60px)'
  }
}));

export default PodcastPreview;