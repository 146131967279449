import React, { useEffect, useState, Fragment } from 'react';

import { Typography, Paper, makeStyles, Card, CardContent,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@material-ui/core';
import { useNotify, useRedirect, Title } from 'react-admin';
import classnames from 'classnames';

import { determinePodcastStatus } from '../../util/PodcastUtil';
import { PublishedIcons } from '../../components/podcasts/PublishedIcons';

import { WordCount } from '../util/WordCount';

export const SelfServeDashboard = () => {
  const classes = useStyles();
  const notify = useNotify();

  const [dashboardData, setDashboardData] = useState();
  const [podcast, setPodcast] = useState();

  useEffect(() => {
      fetch('/backend/rest/podcast-publish')
        .then(response => {
          if (!response.ok) {
            notify("Could not get podcasts!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          if (json.content.length > 0) {
            setPodcast(json.content[0]);
          }
        });
  }, [notify]);

  useEffect(() => {
      fetch('/backend/rest/selfserve/dashboard')
        .then(response => {
          if (!response.ok) {
            notify("Could not get dashboard data!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          setDashboardData(json);
        });
  }, [notify]);

  return (
    <div className={classes.mainContainer}>
      <Card className={classes.cardRoot}>
          <Title title="AudioHarvest account dashboard" />
          <CardContent className={classes.cardContainer}>
            <div className={classes.dashboardRow}>
              <CampaignStatus dashboardData={dashboardData} podcast={podcast}/>
              <CampaignReporting dashboardData={dashboardData}/>
            </div>
            <div className={classes.dashboardRow}>
              <video controls width='100%'>
                  <source src="https://static.audio-harvest.com/onboarding-video.mp4"
                          type="video/mp4"/>
                  Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </CardContent>
      </Card>
    </div>
  );
}

const CampaignStatus = ({dashboardData, podcast}) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <Fragment>
      <Typography
          variant="h4"
          color="inherit"
          className={classes.title}
          id="campaign-status">
        Campaign status
      </Typography>
      <div className={classes.text}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={classes.statusRowHeader}>Voice selected:</td>
              <td><CheckIcon value={dashboardData?.voiceSelected} path="/selfserve/voices"/></td>
            </tr>
            <tr>
              <td className={classes.statusRowHeader}>Site sections selected:</td>
              <td><CheckIcon value={dashboardData?.sectionsSelected} path="/selfserve/sections"/></td>
            </tr>
            <tr>
              <td className={classes.statusRowHeader}>Articles published:</td>
              <td><CheckIcon value={dashboardData?.articlesPublished} path="/scraper/list"/></td>
            </tr>
            <tr>
              <td className={classes.statusRowHeader}>Podcast created:</td>
              <td><CheckIcon value={dashboardData?.podcastCreated} path="/selfserve/podcast"/></td>
            </tr>
            <tr>
              <td className={classes.statusRowHeader}>Podcast published:</td>
              <td>
                <CheckIcon value={determinePodcastStatus(podcast).state === 'published'}/>
                <Button
                  className={classnames(classes.statusButton, {
                    [classes.statusButtonActive]: determinePodcastStatus(podcast).state === 'published',
                    [classes.statusButtonPending]: determinePodcastStatus(podcast).state === 'inprogress',
                    [classes.statusButtonInactive]: !determinePodcastStatus(podcast).state || determinePodcastStatus(podcast).state === 'unpublished'
                    })} variant="contained" disableRipple
                    onClick={e => redirect("/podcast-preview")}>
                  { determinePodcastStatusText(podcast) }
                </Button>
                { determinePodcastStatus(podcast).state === 'published'
                  ? <PublishedIcons record={podcast} /> : null }
              </td>
            </tr>
            <tr>
              <td className={classes.statusRowHeader}>Onsite audio player:</td>
              <td>
                <CheckIcon value={dashboardData?.onsitePlayerStatus}/>
                <Button
                  className={classnames(classes.statusButton, {
                    [classes.statusButtonActive]: dashboardData?.onsitePlayerStatus,
                    [classes.statusButtonInactive]: !dashboardData?.onsitePlayerStatus
                    })} variant="contained" disableRipple
                    onClick={e => redirect("/audio-player-setup")}>
                  {dashboardData?.onsitePlayerStatus ? 'Active' : 'Pending'}
                </Button>
              </td>
            </tr>
            <tr>
              <td className={classnames(classes.statusRowHeader, classes.dashboardWordCount)}>Words used:</td>
              <td className={classes.dashboardWordCount}>
                <WordCount/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

const CheckIcon = ({value, path}) => {
  const classes = useStyles();
  const redirect = useRedirect();

  if (value === true) {
    return <span><img src="/assets/tick.png" className={classes.statusIcon} alt="Item done"/></span>;
  } else if (value === false){
    return <Fragment>
              <span><img src="/assets/no-tick.png" className={classes.statusIcon} alt="Item pending"/></span>
              { path ? <Button variant="contained" className={classnames(classes.statusButton, classes.setupButton)}
                  onClick={() => redirect(path)}>Click to setup</Button> : null }
            </Fragment>;
  } else {
    return <span className={classes.statusIcon}>…</span>;
  }
};

const CampaignReporting = ({dashboardData}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography
          variant="h4"
          color="inherit"
          className={classes.otherTitle}
          id="campaign-reporting">
        Campaign reporting: Overview
      </Typography>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Episodes</TableCell>
              <TableCell align="right">Views</TableCell>
              <TableCell align="right">Downloads / plays</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.summaryrow} component="th" scope="row">Podcast</TableCell>
              <TableCell className={classes.summaryrow} align="right">{new Intl.NumberFormat('en-US').format(dashboardData?.totalEpisodes ? dashboardData?.totalEpisodes : 0)}</TableCell>
              <TableCell className={classes.summaryrow} align="right">{new Intl.NumberFormat('en-US').format(dashboardData?.podcastViews ? dashboardData?.podcastViews : 0)}</TableCell>
              <TableCell className={classes.summaryrow} align="right">{new Intl.NumberFormat('en-US').format(dashboardData?.podcastPlays ? dashboardData?.podcastPlays : 0)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className={classes.summaryrow}>Onsite player</TableCell>
              <TableCell align="right" className={classes.summaryrow}></TableCell>
              <TableCell align="right" className={classes.summaryrow}>{new Intl.NumberFormat('en-US').format(dashboardData?.onsitePlayerViews ? dashboardData?.onsitePlayerViews : 0)}</TableCell>
              <TableCell align="right" className={classes.summaryrow}>{new Intl.NumberFormat('en-US').format(dashboardData?.onsitePlayerPlays ? dashboardData?.onsitePlayerPlays : 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}

const determinePodcastStatusText = (podcast) => {
  var status = determinePodcastStatus(podcast).state;
  if (status === 'unpublished') {
    return "Pending";
  } else if (status === 'published') {
    return "Published";
  } else if (status === 'inprogress') {
    return "Publish in progress...";
  } else {
    return "No podcast yet";
  }
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: '20px',
    width: 'auto',
    textAlign: 'center'
  },
  cardRoot: {
    backgroundColor: 'transparent'
  },
  cardContainer: {
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  dashboardRow: {
    margin: '20px',
    maxWidth: '600px'
  },
  videoWindow: {
    width: '100%',
    minWidth: '300px',
    height: '100%'
  },
  title: {
    textAlign: 'left'
  },
  otherTitle: {
    margin: '20px 0px'
  },
  text: {
    textAlign: 'left',

    '& p': {
      margin: '5px 0px'
    }
  },
  statusButton: {
    fontWeight: 'bold',
    margin: '15px'
  },
  statusButtonActive: {
    color: 'green',
    backgroundColor: '#0FD1B0',

    '&:hover': {
      backgroundColor: '#0FD1B080'
    }
  },
  statusButtonInactive: {
    color: 'grey',
    backgroundColor: 'lightgrey',

    '&:hover': {
      backgroundColor: '#aaaaaa80'
    }
  },
  statusButtonPending: {
    color: 'grey',
    backgroundColor: '#0FD1B0',

    '&:hover': {
      backgroundColor: '#0FD1B080'
    }
  },
  setupButton: {
    margin: '15px',
    color: 'grey',
    backgroundColor: '#0FD1B0',

    '&:hover': {
      backgroundColor: '#0FD1B080'
    }
  },
  statusRowHeader: {
    paddingRight: '40px',
    textTransform: 'capitalize',
    fontSize: '120%'
  },
  table: {
    borderStyle: 'none',
    padding: '20px',

    "& tr": {
      borderBottomStyle: 'dashed',
      borderWidth: '2px',
      borderColor: '#00000022'
    },
    "& tbody tr:last-child": {
      borderBottomStyle: 'hidden'
    }
  },
  summaryrow: {
    color: '#0FD1B0',
    fontWeight: 'bold',
  },
  normalRow: {
    color: 'grey',
    fontWeight: 'bold',
  },
  dashboardWordCount: {
    padding: '30px 0px 0px'
  },
  statusIcon: {
    verticalAlign: 'middle',
    width: '30px',
    height: '30px',
  },
  imageSpacer: {
    width: '30px',
    height: '30px',
    display: 'inline-block'
  }
}));