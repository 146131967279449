import React, { useState, useEffect } from 'react';
import { useNotify, useRedirect, Loading, Title } from 'react-admin';

import { Paper, makeStyles,
  List, ListItem, ListItemIcon, ListItemText, Checkbox, InputLabel, MenuItem, FormControl,
  Select, Button, Typography } from '@material-ui/core';

import { continueButtonStyle } from '../style/ContinueButtonStyle.js'
import { mainContainerStyle } from '../style/mainContainerStyle';

const useStyles = makeStyles(theme => ({
  innerContainer: {
    padding: '15px',
    maxWidth: '600px',
    margin: 'auto'
  },
  elementContainer: {
    margin: '0px auto'
  },
  headerText: {
    margin: '20px 0px',
  },
  languageSelect: {
    margin: '10px 40px',
    width: '80%'
  },
  text: {
    margin: '10px 20px',
    textAlign: 'left'
  },
  continueButton: continueButtonStyle,
  voiceSelectAudio: {
    height: '30px'
  },
}));

const VoiceSelectPage = (endPoint, title) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const mainContainerClasses = mainContainerStyle();

  const [selectedVoice, setSelectedVoice] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("British English");
  const [voices, setVoices] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
      fetch('/backend/rest/selfserve/voices')
        .then(response => {
          if (!response.ok) {
            notify("Could not get available voices!", { type: "error" });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          setVoices(json);
          setLanguages(new Set(json.map(entry => entry.languageName)));
        });
  }, [notify]);

  useEffect(() => {
      fetch('/backend/rest/selfserve/voices/selected')
        .then(response => {
          if (!response.ok) {
            notify("Could not get current voice!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          setSelectedVoice(json.second);
          setSelectedLanguage(json.second.languageName);
        });
  }, [notify]);

  const continueClicked = () => {
    if (selectedVoice) {
      fetch('/backend/rest/selfserve/voices',
        { method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({voice: selectedVoice.id, language: selectedVoice.languageCode})
        })
        .then(response => {
          if (!response.ok) {
            notify("Could not set selected voice!", { type: "error" });
            return Promise.reject();
          }
          return response.text();
        })
        .then(ignored => {
          notify("Voice selection saved!");
          redirect("/selfserve/sections");
        });
    }
  }

  const languageSelected = (e) => {
    console.log("Selected language: ", e.target.value);
    setSelectedLanguage(e.target.value);
  }

  if (!selectedLanguage || !selectedVoice || voices.length === 0) {
    return (<Loading loadingPrimary="Loading voices" loadingSecondary="Please wait..." />);
  }

  return (
    <div className={mainContainerClasses.mainContainer}>
      <Title title="Voice select" />
      <div>
        <Typography variant="h6" className={classes.headerText}>
            Select the voice to use for the podcast.<br/>All new audio files created will use this voice.
        </Typography>
        <Paper className={classes.innerContainer}>
          <div>
            <FormControl required className={classes.languageSelect}>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={selectedLanguage}
                onChange={languageSelected}>
                { [...languages].map(language => <MenuItem key={language} value={language}>{language}</MenuItem>) }
              </Select>
            </FormControl>
            <List component="div" dense={false}>
              { voices.filter(voice => voice.languageName === selectedLanguage)
                      .map(voice =>
                          <ListItem key={voice.id} button onClick={(e) => { setSelectedVoice(voice); e.stopPropagation(); }}>
                            <ListItemIcon style={{ minWidth: '40px' }}>
                              <Checkbox
                                 edge="start"
                                 checked={selectedVoice.id === voice.id && selectedVoice.languageCode === voice.languageCode}
                                 color="primary"
                                 tabIndex={-1}
                                 disableRipple
                               />
                            </ListItemIcon>
                            <ListItemText primary={voice.description} />
                            <audio controls controlsList="nodownload" preload="metadata"
                                src={voice.sampleUrl} className={classes.voiceSelectAudio}>
                              Your browser does not support the audio element.
                            </audio>
                          </ListItem>)
              }
            </List>
          </div>
        </Paper>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={classes.continueButton}
          disabled={!selectedVoice}
          onClick={continueClicked}>
          Continue ...
        </Button>
      </div>
    </div>
  );
}

export default VoiceSelectPage;
