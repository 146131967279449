import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import { useGetResourceLabel, getResources } from 'ra-core';
import { shallowEqual, useSelector } from 'react-redux';
import { hasRole } from './authProvider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import DefaultIcon from '@material-ui/icons/ViewList';

export const AudioHarvestMenu = ({hasDashboard, dense, ...props}) => {
  const classes = useStyles();

  const resources = useSelector(getResources, shallowEqual);
  const getResourceLabel = useGetResourceLabel();

  const categories = Array.from(new Set(resources.map(r => r.options.category)));

  const [categoryOpen, setCategoryOpen] = React.useState(new Set());

  const toggleCategory = (cat) => {
    categoryOpen.has(cat) ? categoryOpen.delete(cat) : categoryOpen.add(cat);
    setCategoryOpen(new Set(categoryOpen));
  }

  if (window.location.pathname.startsWith('/admin/')
        && sessionStorage.getItem('siteKey')
        && !hasRole('ADMIN')) {
    return <></>; // Hide sidebar
  }

  return (
            <>
                {hasDashboard && <DashboardMenuItem dense={dense} />}
                <List className={classes.list}>
                  { resources
                     .filter(r => r.hasList)
                     .filter(r => r.options.category == null || r.options.category === '')
                     .map(resource => (
                         <MenuItemLink
                             className={classes.topMenuItem}
                             key={resource.name}
                             to={{
                                 pathname: `/${resource.name}`,
                                 state: { _scrollToTop: true },
                             }}
                             primaryText={getResourceLabel(resource.name, 2)}
                             leftIcon={
                                 resource.icon ? (
                                     <resource.icon />
                                 ) : (
                                     <DefaultIcon />
                                 )
                             }
                             dense={dense}
                         />
                     ))
                  }
                  { categories
                    .filter(categoryName => categoryName != null && categoryName !== '')
                    .map(categoryName =>
                    <div key={categoryName}>
                      <ListItem button onClick={() => toggleCategory(categoryName)} className={classes.categoryButton}>
                        <ListItemIcon>
                          <DefaultIcon />
                        </ListItemIcon>
                        <ListItemText primary={categoryName} className={classes.category} />
                        {categoryOpen.has(categoryName) ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={categoryOpen.has(categoryName)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                         {resources
                             .filter(r => r.hasList)
                             .filter(r => r.options.category === categoryName)
                             .map(resource => (
                                 <MenuItemLink
                                     className={classes.menuItem}
                                     key={resource.name}
                                     to={{
                                         pathname: `/${resource.name}`,
                                         state: { _scrollToTop: true },
                                     }}
                                     primaryText={getResourceLabel(resource.name, 2)}
                                     leftIcon={
                                         resource.icon ? (
                                             <resource.icon />
                                         ) : (
                                             <DefaultIcon />
                                         )
                                     }
                                     dense={dense}
                                 />
                             ))}
                        </List>
                      </Collapse>
                    </div>
                  ) }
                </List>
            </>);
}

const useStyles = makeStyles({
  list: {
    width: '250px',
    padding: '0px'
  },
  category: {
    "& span": {
     fontWeight: '600'
    }
  },
  menuItem: {
    marginLeft: '10px'
  },
  categoryButton: {
    paddingTop: '4px',
    paddingBottom: '4px',

    "& .MuiListItemIcon-root": {
      minWidth: '40px'
    }
  }
});

