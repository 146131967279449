import React from 'react';
import { Admin, Resource, useRedirect } from 'react-admin';
import { Route } from 'react-router-dom';
import jsonServerProvider from '../lib/ra-custom-rest';
import { authProvider, hasPermission } from '../util/authProvider';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import { createBrowserHistory } from 'history';
import ReactGA from "react-ga4";

import AudioHarvestLayout from '../util/AudioHarvestLayout'
import { ListSections } from './components/ListSections'
import { ListScrapedPages, CreateScrapedPage, EditScrapedPage } from './components/scraped_pages'
import { PodcastCreate, PodcastEdit, PodcastCreateOrEdit } from './components/podcasts'
import { PodcastPreviewPage } from './components/podcasts'
import { PaymentOverview, PaymentChange } from '../selfserve/components/payment';
import { SelfServeDashboard } from './components/SelfServeDashboard';
import { UserProfile } from './components/user/UserProfile';
import { PodcastReportPage, WidgetReportPage } from './components/report';
import { PrePostRoll } from './components/pre-post-roll';
import { ListAudioPages, EditAudioPage, CreateAudioPage } from './components/audio';

import VoiceSelectPage from './components/VoiceSelectPage';
import AudioPlayerSetup from './components/AudioPlayerSetup';

import SettingsVoiceRoundedIcon from '@material-ui/icons/SettingsVoiceRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AudiotrackRoundedIcon from '@material-ui/icons/AudiotrackRounded';

import { theme } from '../theme';
import './SelfServeApp.css';

import { AHLoginPage } from './components/login/AHLoginPage';

// create the history
const history = createBrowserHistory();

ReactGA.initialize("G-MV635K77PL");

// listen for a route change and record page view
history.listen(location => {
  ReactGA.pageview(location.pathname);
});

// record the first pageview because listen will not be called
ReactGA.pageview(window.location.pathname);

const dataProvider = jsonServerProvider('/backend/rest');

const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });

const SelfServeApp = () => {
    return (
     <Admin theme={theme} layout={AudioHarvestLayout} title="AudioHarvest UI"
        dashboard={SelfServeDashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        disableTelemetry
        loginPage={AHLoginPage}
        history={history}
        customRoutes={[<Route exact path="/user-profile" component={UserProfile}/>]}>
        {permissions => createMenuItems(permissions)}
    </Admin>);
}

const createMenuItems = (permissions) => {
    var menuItems = [];

    menuItems.push(<Resource name="selfserve/voices" options={{ label: 'Voice setup' }}
                        list={VoiceSelectPage}
                        icon={RecordVoiceOverIcon}/>);
    menuItems.push(<Resource name="selfserve/sections" options={{ label: 'Site section selection' }} list={ListSections}
                        icon={FormatListBulletedIcon} />);
    if (hasPermission(permissions, 'PrePostRoll')) {
      menuItems.push(<Resource name="selfserve/pre-post-roll" options={{ label: 'Pre/post roll' }} list={PrePostRoll}
                          icon={SurroundSoundIcon} />);
    }
    if (hasPermission(permissions, 'CreateScrapedPages')) {
      menuItems.push(<Resource name="scraper/create" options={{ label: 'Create article', category: 'Create audio content' }} list={doRedirect("create-article", "/scraper/list/create")} icon={LiveHelpIcon}/>);
    }
    if (hasPermission(permissions, 'AudioPages')) {
      menuItems.push(<Resource name="scraper/audio-create" options={{ label: 'Upload audio file', category: 'Create audio content' }} list={doRedirect("create-audiofile", "/scraper/audio-articles/create")} icon={LiveHelpIcon}/>);
    }
    menuItems.push(<Resource name="scraper/list" options={{ label: 'Article review', category: 'Manage content' }} list={ListScrapedPages}
                        create={CreateScrapedPage} edit={EditScrapedPage}
                        icon={DescriptionRoundedIcon} />);
    if (hasPermission(permissions, 'AudioPages')) {
      menuItems.push(<Resource name="scraper/audio-articles" options={{ label: 'Audiofile review', category: 'Manage content' }} list={ListAudioPages}
                                    create={CreateAudioPage} edit={EditAudioPage} icon={AudiotrackRoundedIcon} />);
    }
    menuItems.push(<Resource name="selfserve/podcast" options={{ label: 'Setup', category: 'Podcast setup' }} list={PodcastCreateOrEdit}
                      create={PodcastCreate} edit={PodcastEdit} icon={SettingsVoiceRoundedIcon} />);
    menuItems.push(<Resource name="podcast-preview" options={{ label: 'Distribution', category: 'Podcast setup' }} list={PodcastPreviewPage}
                        icon={FindInPageIcon} />);
    menuItems.push(<Resource name="audio-player-setup" options={{ label: 'On-site player setup', category: 'Podcast setup' }}
                        list={AudioPlayerSetup} icon={PlayCircleOutlineIcon}/>);

    menuItems.push(<Resource name="report/podcast" options={{ label: 'Podcast reporting', category: 'Reporting' }}
                                   list={PodcastReportPage}
                                   icon={AssessmentRoundedIcon}/>);
    menuItems.push(<Resource name="report/widget" options={{ label: 'On-site reporting', category: 'Reporting' }}
                                   list={WidgetReportPage}
                                   icon={AssessmentRoundedIcon}/>);
    if (hasPermission(permissions, 'SubscriptionSelect')) {
      menuItems.push(<Resource name="subscription-select" options={{ label: 'Subscription type', category: 'Subscription' }}
                        list={PaymentChange} icon={CreditCardIcon}/>);
    }
    if (hasPermission(permissions, 'SubscriptionDetails')) {
      menuItems.push(<Resource name="subscription-details" options={{ label: 'Subscription details', category: 'Subscription' }}
                        list={PaymentOverview} icon={CreditCardIcon}/>);
    }

    menuItems.push(<Resource name="faqs" options={{ label: 'FAQ\'s', category: 'Resources' }} list={doRedirect("faqs", "/", "https://www.audio-harvest.com/faqs/")}
                      icon={LiveHelpIcon}/>);
    menuItems.push(<Resource name="support" options={{ label: 'Support', category: 'Resources' }} list={doRedirect("support", "/", "https://support.audio-harvest.com/")}
                      icon={ContactSupportIcon}/>);
    menuItems.push(<Resource name="knowledge-base" options={{ label: 'Knowledge base', category: 'Resources' }} list={doRedirect("knowledge-base", "/", "https://support.audio-harvest.com/")}
                      icon={ContactSupportIcon}/>);

    return menuItems;
}

const doRedirect = (name, innerRedirect, externalRedirect = null) => () => {
  const redirect = useRedirect();

  redirect(innerRedirect);
  if (externalRedirect) {
    window.open(externalRedirect, name);
  }

  return <></>;
}

export default SelfServeApp;