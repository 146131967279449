export const createCustomer = (customerInfo, setCustomer) => {
  return fetch('/backend/rest/payment/subscription/create-customer', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: customerInfo.customer.name,
      email: customerInfo.customer.email,
      vatNumber: customerInfo.customer.tax_ids?.data[0].value,
      vatType: customerInfo.customer.tax_ids?.data[0].type,
    }),
  })
  .then(response => {
     if (response.status < 200 || response.status >= 300) {
         console.log("Could not create new customer!");
         return Promise.reject();
     }
     return response.json();
  })
  .then((result) => {
    customerInfo.customer = result.customer;
    setCustomer(customerInfo);
    return Promise.resolve();
  });
}

export const createSubscription = (stripe, customerId, paymentMethod, priceId, couponCode, onSubscriptionComplete, displayError) => {
  fetch('/backend/rest/payment/subscription/create-subscription', {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      customerId: customerId,
      paymentMethodId: paymentMethod?.id,
      priceId: priceId,
      couponCode: couponCode
    }),
  })
  .then(response => {
    if (response.status < 200 || response.status >= 300) {
       console.log("Could not create subscription!", response.status);
       return Promise.reject(response);
    }
    return response.json();
  })
  // If the card is declined, display an error to the user.
  .then((result) => {
    if (result.error) {
      // The card had an error when trying to attach it to a customer
      throw result;
    }
    return result;
  })
  // Normalize the result to contain the object returned
  // by Stripe. Add the additional details we need.
  .then((result) => {
    return {
      // Use the Stripe 'object' property on the
      // returned result to understand what object is returned.
      subscription: result,
      paymentMethodId: paymentMethod?.id,
      stripe: stripe
    };
  })
  // Some payment methods require a customer to do additional
  // authentication with their financial institution.
  // Eg: 2FA for cards.
  .then(handleCardSetupRequired)
  .then(handlePaymentThatRequiresCustomerAction)
  // If attaching this card to a Customer object succeeds,
  // but attempts to charge the customer fail. You will
  // get a requires_payment_method error.
  .then(handleRequiresPaymentMethod)
  // No more actions required. Provision your service for the user.
  .then(onSubscriptionComplete)
  .catch((error) => {
    // An error has happened. Display the failure to the user here.
    // We utilize the HTML element we created.
    console.log("Got error: ", error);

    if (error instanceof Response) {
      error.json()
        .then(json => displayError(json.message));
    } else {
      displayError("Error: " + JSON.stringify(error))
    }
  })
}

function handleCardSetupRequired({
  subscription,
  paymentMethodId,
  stripe
})
{
  let setupIntent = subscription.pending_setup_intent;

  if (setupIntent && setupIntent.status === 'requires_action')
  {
    return stripe
      .confirmCardSetup(setupIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw result;
        } else {
          if (result.setupIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to setup_intent.succeeded.
            return { stripe, subscription, paymentMethodId };
          }
        }
      });
  }
  else {
    // No customer action needed
    return { stripe, subscription, paymentMethodId };
  }
}

function handlePaymentThatRequiresCustomerAction({
  stripe,
  subscription,
  paymentMethodId,
  isRetry,
}) {
  if (subscription && subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, paymentMethodId };
  }

  let paymentIntent = subscription.latest_invoice.payment_intent;

  if (
    paymentIntent.status === 'requires_action' ||
    (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw result;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.paid. This webhook endpoint returns an Invoice.
            return {
              subscription: subscription,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      });
  } else {
    // No customer action needed
    return { subscription, paymentMethodId };
  }
}

function handleRequiresPaymentMethod({
  subscription,
  paymentMethodId,
}) {
  if (subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, paymentMethodId };
  } else if (
    subscription.latest_invoice.payment_intent.status ===
    'requires_payment_method'
  ) {
    // Using sessionStorage to store the state of the retry here
    // (feel free to replace with what you prefer)
    // Store the latest invoice ID and status
    sessionStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
    sessionStorage.setItem(
      'latestInvoicePaymentIntentStatus',
      subscription.latest_invoice.payment_intent.status
    );
    throw new Error({ error: { message: 'Your card was declined.' } });
  } else {
    return { subscription, paymentMethodId };
  }
}

//function onSubscriptionComplete(result) {
//  console.log(result);
//  // Payment was successful. Provision access to your service.
//  // Remove invoice from sessionStorage because payment is now complete.
//  //  clearCache();
//  // Change your UI to show a success message to your customer.
//  // onSubscriptionSampleDemoComplete(result);
//  // Call your backend to grant access to your service based on
//  // the product your customer subscribed to.
//  // Get the product by using result.subscription.price.product
//}

