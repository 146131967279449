import React, { useState, useEffect } from 'react';
import { Filter, TextInput, AutocompleteInput, NullableBooleanInput } from 'react-admin';

export const PostFilter = ({setHoverElement, notify, ...props}) => {
    const [sections, setSections] = useState([])

    useEffect(() => {
      fetch('/backend/rest/scraper/sections')
        .then(response => {
            return response.ok ? response.json() : Promise.reject(response.statusText);
        })
        .then((data) => {
          var sectionsMap = data.map(i => {
            var item = {}
            item.id = i
            item.name = i
            return item;
          })
          setSections(sectionsMap)
        })
        .catch(error => {
          notify('Failed to fetch available sections: ' + error, { type: 'error' });
        });
    }, [notify]);

  return (
    <Filter {...props}
          onMouseEnter={() => setHoverElement("filters")}
          onMouseLeave={() => setHoverElement()}>
        <TextInput label="Search" source="q" alwaysOn resettable={true} />
        <AutocompleteInput label="Section" source="section" choices={sections} defaultValue="" alwaysOn resettable={true} />
        <NullableBooleanInput label="Published" source="published" alwaysOn />
    </Filter>);
}

export default PostFilter;
