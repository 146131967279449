import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './selfserve/index.css';
import SelfServeApp from './selfserve/SelfServeApp';

const getEnvironment = () => {
  if (window.location.hostname.includes('audio-harvest.com')) {
    return 'prod';
  } else if (window.location.hostname.includes('elasticbeanstalk')) {
    return 'staging';
  } else {
    return 'dev';
  }
}

Sentry.init({
  dsn: "https://5a79ff9841b947f9b28cc0faf5a108f7@o1075201.ingest.sentry.io/6075524",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: getEnvironment()
});

ReactDOM.render(
  <React.StrictMode>
    <SelfServeApp />
  </React.StrictMode>,
  document.getElementById('root')
);
