import React, {useState, useEffect} from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useNotify } from 'react-admin';

import { infoBoxStyles } from './ReportStyles';

export const PodcastInfoBox = ({title, ...rest}) => {
  const classes = infoBoxStyles();
  const notify = useNotify();

  const [episodes, setEpisodes] = useState();
  const [podcasts, setPodcasts] = useState();

  useEffect(() => {
      fetch('/backend/rest/podcast-publish')
        .then(response => {
          if (!response.ok) {
            notify("Could not get podcasts!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          setPodcasts(json.content.length);
          if (json.content.length > 0) {
            const totalEpisodes = json.content.map(podcast => podcast.episodeCount).reduce((a,b) => a+b);
            setEpisodes(totalEpisodes);
          }
        });
  }, [notify]);


  return (
    <div {...rest}>
      <Paper className={classes.graphPaper}>
        <Typography variant="h5" className={classes.headerText}>{title}</Typography>
        <Typography variant="h4" className={classes.doughnutNumber}>{episodes ? new Intl.NumberFormat('en-US').format(episodes) : '0'}</Typography>
        <Typography variant="h4" className={classes.downloadsText}>Episodes</Typography>
        <Typography variant="span" className={classes.viewsNumber}>{podcasts ? new Intl.NumberFormat('en-US').format(podcasts) : '0'}</Typography>
        <Typography variant="span" className={classes.viewsText}> podcasts</Typography>
      </Paper>
    </div>
  );
}

export default PodcastInfoBox;
