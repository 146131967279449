import React, {useState} from 'react';
import { Title } from 'react-admin';
import { Select, FormControl, MenuItem, Typography } from '@material-ui/core';

import LineGraph from './LineGraph';
import DoughnutGraph from './DoughnutGraph';
import WidgetInfoBox from './WidgetInfoBox';

import createReport from '../../../components/reporting/report';

import { useStyles, tableStyles } from './ReportStyles';

export const WidgetReportPage = () => {
  const classes = useStyles();

  const [timeRange, setTimeRange] = useState('last_month');
  const [period, setPeriod] = useState('DAYS');
  const [lastNum, setLastNum] = useState(30);

  const timeranges = [
    { id: 'last_day', name: 'Last day' },
    { id: 'last_week', name: 'Last week' },
    { id: 'last_month', name: 'Last month' },
//    { id: 'last_year', name: 'Last year' },
  ];

  const onTimerangeChange = (e) => {
    const value = e.target.value;
    switch (value) {
      case 'last_day':
        setPeriod('HOURS');
        setLastNum(24);
        setTimeRange(value);
        break;
      case 'last_week':
        setPeriod('DAYS');
        setLastNum(7);
        setTimeRange(value);
        break;
      case 'last_month':
      default:
        setPeriod('DAYS');
        setLastNum(30);
        setTimeRange(value);
        break;
/*
      case 'last_year':
        setPeriod('MONTHS');
        setLastNum(12);
        setTimeRange(value);
        break;
*/
    }
  };

  return (
    <div>
      <Title title="On-site player reporting" />
      <div className={classes.header}>
        <Typography variant="h3"  className={classes.title}>Onsite player reporting </Typography>
        <FormControl variant="outlined">
          <Select id="select-timerange" value={timeRange} onChange={onTimerangeChange}>
            { timeranges.map(entry => {
                return (<MenuItem value={entry.id}>{entry.name}</MenuItem>);
              })
            }
          </Select>
        </FormControl>
      </div>
      <div className={classes.graphContainer}>
        <DoughnutGraph className={classes.doughnut} endpoint="widget/doughnut" period={period} lastNum={lastNum}/>
        <WidgetInfoBox className={classes.doughnut} />
        <LineGraph title="On-site player data" className={classes.graph}
            endpoint="report/widget/interval" period={period} lastNum={lastNum} />
        { createReport("report/widget", "Overview all-time", tableStyles)() }
      </div>
    </div>
  );
};

