export const podcastCategoryChoices = [
{ id: "Arts", name: "Arts" },
{ id: "Arts_Books", name: "Arts > Books" },
{ id: "Arts_Design", name: "Arts > Design" },
{ id: "Arts_FashionBeauty", name: "Arts > Fashion & Beauty" },
{ id: "Arts_Food", name: "Arts > Food" },
{ id: "Arts_PerformingArts", name: "Arts > Performing Arts" },
{ id: "Arts_VisualArts", name: "Arts > Visual Arts" },
{ id: "Business", name: "Business" },
{ id: "Business_Careers", name: "Business > Careers" },
{ id: "Business_Entrepreneurship", name: "Business > Entrepreneurship" },
{ id: "Business_Investing", name: "Business > Investing" },
{ id: "Business_Management", name: "Business > Management" },
{ id: "Business_Marketing", name: "Business > Marketing" },
{ id: "Business_Non_profit", name: "Business > Non-profit" },
{ id: "Comedy", name: "Comedy" },
{ id: "Comedy_ComedyInterviews", name: "Comedy > Comedy Interviews" },
{ id: "Comedy_Improv", name: "Comedy > Improv" },
{ id: "Comedy_Standup", name: "Comedy > Standup" },
{ id: "Education", name: "Education" },
{ id: "Education_Courses", name: "Education > Courses" },
{ id: "Education_Howto", name: "Education > How to" },
{ id: "Education_LanguageLearning", name: "Education > Language Learning" },
{ id: "Education_SelfImprovement", name: "Education > Self Improvement" },
{ id: "Fiction", name: "Fiction" },
{ id: "Fiction_ComedyFiction", name: "Fiction > Comedy Fiction" },
{ id: "Fiction_Drama", name: "Fiction > Drama" },
{ id: "Fiction_ScienceFiction", name: "Fiction > Science Fiction" },
{ id: "Government", name: "Government" },
{ id: "History", name: "History" },
{ id: "HealthFitness", name: "Health & Fitness" },
{ id: "HealthFitness_AlternativeHealth", name: "Health & Fitness > Alternative Health" },
{ id: "HealthFitness_Fitness", name: "Health & Fitness > Fitness" },
{ id: "HealthFitness_Medicine", name: "Health & Fitness > Medicine" },
{ id: "HealthFitness_MentalHealth", name: "Health & Fitness > Mental Health" },
{ id: "HealthFitness_Nutrition", name: "Health & Fitness > Nutrition" },
{ id: "HealthFitness_Sexuality", name: "Health & Fitness > Sexuality" },
{ id: "KidsFamily", name: "Kids & Family" },
{ id: "KidsFamily_EducationforKids", name: "Kids & Family > Education for Kids" },
{ id: "KidsFamily_Parenting", name: "Kids & Family > Parenting" },
{ id: "KidsFamily_PetsAnimals", name: "Kids & Family > Pets & Animals" },
{ id: "KidsFamily_StoriesforKids", name: "Kids & Family > Stories for Kids" },
{ id: "Leisure", name: "Leisure" },
{ id: "Leisure_AnimationManga", name: "Leisure > Animation & Manga" },
{ id: "Leisure_Automotive", name: "Leisure > Automotive" },
{ id: "Leisure_Aviation", name: "Leisure > Aviation" },
{ id: "Leisure_Crafts", name: "Leisure > Crafts" },
{ id: "Leisure_Games", name: "Leisure > Games" },
{ id: "Leisure_Hobbies", name: "Leisure > Hobbies" },
{ id: "Leisure_HomeGarden", name: "Leisure > Home & Garden" },
{ id: "Leisure_VideoGames", name: "Leisure > Video Games" },
{ id: "Music", name: "Music" },
{ id: "Music_MusicCommentary", name: "Music > Music Commentary" },
{ id: "Music_MusicHistory", name: "Music > Music History" },
{ id: "Music_MusicInterviews", name: "Music > Music Interviews" },
{ id: "News", name: "News" },
{ id: "News_BusinessNews", name: "News > Business News" },
{ id: "News_DailyNews", name: "News > Daily News" },
{ id: "News_EntertainmentNews", name: "News > Entertainment News" },
{ id: "News_NewsCommentary", name: "News > News Commentary" },
{ id: "News_Politics", name: "News > Politics" },
{ id: "News_SportsNews", name: "News > Sports News" },
{ id: "News_TechNews", name: "News > Tech News" },
{ id: "ReligionSpirituality", name: "Religion & Spirituality" },
{ id: "ReligionSpirituality_Buddhism", name: "Religion & Spirituality > Buddhism" },
{ id: "ReligionSpirituality_Christianity", name: "Religion & Spirituality > Christianity" },
{ id: "ReligionSpirituality_Hinduism", name: "Religion & Spirituality > Hinduism" },
{ id: "ReligionSpirituality_Islam", name: "Religion & Spirituality > Islam" },
{ id: "ReligionSpirituality_Judaism", name: "Religion & Spirituality > Judaism" },
{ id: "ReligionSpirituality_Religion", name: "Religion & Spirituality > Religion" },
{ id: "ReligionSpirituality_Spirituality", name: "Religion & Spirituality > Spirituality" },
{ id: "Science", name: "Science" },
{ id: "Science_Astronomy", name: "Science > Astronomy" },
{ id: "Science_Chemistry", name: "Science > Chemistry" },
{ id: "Science_EarthSciences", name: "Science > Earth Sciences" },
{ id: "Science_LifeSciences", name: "Science > Life Sciences" },
{ id: "Science_Mathematics", name: "Science > Mathematics" },
{ id: "Science_NaturalSciences", name: "Science > Natural Sciences" },
{ id: "Science_Nature", name: "Science > Nature" },
{ id: "Science_Physics", name: "Science > Physics" },
{ id: "Science_SocialSciences", name: "Science > Social Sciences" },
{ id: "SocietyCulture", name: "Society & Culture" },
{ id: "SocietyCulture_Documentary", name: "Society & Culture > Documentary" },
{ id: "SocietyCulture_PersonalJournals", name: "Society & Culture > Personal Journals" },
{ id: "SocietyCulture_Philosophy", name: "Society & Culture > Philosophy" },
{ id: "SocietyCulture_PlacesTravel", name: "Society & Culture > Places & Travel" },
{ id: "SocietyCulture_Relationships", name: "Society & Culture > Relationships" },
{ id: "Sports", name: "Sports" },
{ id: "Sports_Baseball", name: "Sports > Baseball" },
{ id: "Sports_Basketball", name: "Sports > Basketball" },
{ id: "Sports_Cricket", name: "Sports > Cricket" },
{ id: "Sports_FantasySports", name: "Sports > Fantasy Sports" },
{ id: "Sports_Football", name: "Sports > Football" },
{ id: "Sports_Golf", name: "Sports > Golf" },
{ id: "Sports_Hockey", name: "Sports > Hockey" },
{ id: "Sports_Rugby", name: "Sports > Rugby" },
{ id: "Sports_Running", name: "Sports > Running" },
{ id: "Sports_Soccer", name: "Sports > Soccer" },
{ id: "Sports_Swimming", name: "Sports > Swimming" },
{ id: "Sports_Tennis", name: "Sports > Tennis" },
{ id: "Sports_Volleyball", name: "Sports > Volleyball" },
{ id: "Sports_Wilderness", name: "Sports > Wilderness" },
{ id: "Sports_Wrestling", name: "Sports > Wrestling" },
{ id: "Technology", name: "Technology" },
{ id: "TrueCrime", name: "True Crime" },
{ id: "TVFilm", name: "TV & Film" },
{ id: "TVFilm_AfterShows", name: "TV & Film > After Shows" },
{ id: "TVFilm_FilmHistory", name: "TV & Film > Film History" },
{ id: "TVFilm_FilmInterviews", name: "TV & Film > Film Interviews" },
{ id: "TVFilm_FilmReviews", name: "TV & Film > Film Reviews" },
{ id: "TVFilm_TVReviews", name: "TV & Film > TV Reviews" }
]

export const convertPodcastKeyToName = (key) => {
  for (var i in podcastCategoryChoices) {
    if (key === podcastCategoryChoices[i].id) {
        return podcastCategoryChoices[i].name;
    }
  }
  return key;
}

export const explicitnessChoices = [
 {id: "NO", name: "No"},
 {id: "YES", name: "Yes"},
 {id: "CLEAN", name: "Cleaned"}
]

export const determinePodcastStatus = (selectedPodcast) => {
  if (!selectedPodcast) {
    return { text: 'No podcast', state: null, action: null };
  }

  if (selectedPodcast.spotifyState === 'UNPUBLISHED'
        && selectedPodcast.appleState === 'UNPUBLISHED'
        && selectedPodcast.googleState === 'UNPUBLISHED') {
    if (selectedPodcast.episodeCount > 0) {
      return { text: 'Publish', state: 'unpublished',  action: 'publish' };
    } else {
      return { text: 'No episodes!', state: 'no-episodes',  action: null };
    }
  }
  if (selectedPodcast?.spotifyState === 'PUBLISHED'
        || selectedPodcast?.appleState === 'PUBLISHED'
        || selectedPodcast?.googleState === 'PUBLISHED') {
    return { text: 'Unpublish', state: 'published',  action: 'unpublish' };
  }

  return { text: 'Publish in progress...', state: 'inprogress',  action: null };
}
