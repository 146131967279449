import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles, Paper, Button } from '@material-ui/core';
import { useDataProvider, useNotify, Toolbar, SaveButton, Confirm,
        TextInput, PasswordInput, SaveContextProvider, SimpleForm,
        required, regex } from 'react-admin';

import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import RestorePageIcon from '@material-ui/icons/RestorePage';

import { validatePasswordUpdate, nameRegex, usernameRegex } from '../../../components/user/validation.js'

import { mainContainerStyle } from '../../style/mainContainerStyle';

export const UserProfile = (props) => {
  const mainContainerClasses = mainContainerStyle();
  const classes = useStyles();

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [identity, setIdentity] = useState();
  const [siteInfo, setSiteInfo] = useState();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    fetch('/backend/rest/user/info')
      .then(response => {
        if (!response.ok) {
          notify("Could not get user info!", { type: 'error' });
          return Promise.reject();
        }
        return response.json();
      })
      .then(json => {
        setIdentity(json);
      });
  }, [notify]);


  useEffect(() => {
    fetch('/backend/rest/siteconfig/' + sessionStorage.getItem('siteKey'))
      .then(response => {
        if (!response.ok) {
          notify("Could not get site info!", { type: 'error' });
          return Promise.reject();
        }
        return response.json();
      })
      .then(json => {
        setSiteInfo(json);
      });
  }, [notify]);

  const handleSave = useCallback((values) => {
    dataProvider.update('user/list',
      { id: identity.id,
        data: values
      })
      .then(ignored => notify('User details updated!\nIf you changed your email, a confirmation mail will be sent to your new email address!'))
      .catch(error => notify('Could not update user: ' + error.message, { type: 'error' }));
  }, [identity, notify, dataProvider]);

  const saveContext = useMemo(() => ({
    save: handleSave,
  }), [handleSave]);

  const enableDisableAccount = () => {
    fetch('/backend/rest/selfserve/account-status/' + (siteInfo.accountDisabled ? 'enable' : 'disable'))
      .then(response => {
        if (!response.ok) {
          notify("Could not disable account!", { type: 'error' });
          return Promise.reject();
        }
        return response.json();
      })
      .then(json => {
        setSiteInfo(json);
      });
  }

  const PostEditToolbar = props => (
      <Toolbar {...props} >
          <SaveButton />
      </Toolbar>
  );

  return (
    <div className={mainContainerClasses.mainContainer}>
      <Paper className={classes.mainPaper}>
        <SaveContextProvider value={saveContext}>
          <SimpleForm mutationMode="pessimistic" validate={validatePasswordUpdate}
              submitOnEnter={false} save={handleSave} toolbar={<PostEditToolbar />} record={identity ? identity : {}} >
            <p>Update your profile details below:</p>
            <TextInput label="Name" source="name"
                validate={[required(), regex(nameRegex, "Fill in name!")]} fullWidth/>
            <TextInput label="Username / Email" source="username"
                validate={[required(), regex(usernameRegex, "Fill in email!")]} fullWidth
                helperText="Changing your username / email address will only take effect after clicking the confirmation link in the email that will be sent to your new email address."/>
            <p fullWidth>Fill in the following fields if you want to change your password</p>
            <PasswordInput label="Current password" source="password" fullWidth/>
            <PasswordInput label="New password" source="newPassword" fullWidth/>
            <PasswordInput label="Repeat new password" source="newPasswordConfirm" fullWidth/>
          </SimpleForm>
        </SaveContextProvider>
      </Paper>
      <Paper className={classes.deleteAccountPaper}>
        <p>Press the button below to <b>{siteInfo?.accountDisabled ? 'enable' : 'disable'}</b> your account.</p>
        {siteInfo?.accountDisabled
             ? <p>This will re-enable your account, and newly published articles will be added to your account soon.</p>
             : <p>This will cancel your subscription, trigger the unpublishing of your podcast(s), and stop updating your account with new content.</p> }
        <Confirm
            isOpen={confirmDeleteOpen}
            title={siteInfo?.accountDisabled ? "Enable account" : "Disable account" }
            content={siteInfo?.accountDisabled
                                  ? <div>
                                      <p>Are you sure you want to enable your account?</p>
                                    </div>
                                  : <div>
                                      <p>Are you sure you want to disable your account? This will <b>cancel your subscription, unpublish and delete</b> your podcast(s), and no new articles will be added to your account.</p>
                                      <p>The on-site widget will stop appearing on your website pages.</p>
                                    </div>}
            confirm={siteInfo?.accountDisabled ? "Enable account" : "Disable account" }
            confirmColor="primary"
            ConfirmIcon={RestorePageIcon}
            CancelIcon={CancelIcon}
            cancel="Cancel"
            onConfirm={() => { setConfirmDeleteOpen(false); enableDisableAccount(); }}
            onClose={() => { setConfirmDeleteOpen(false); }}
        />
        <Button
          variant="contained"
          color="secondary"
          className={classes.deleteAccountButton}
          startIcon={<DeleteIcon />}
          onClick={() => setConfirmDeleteOpen(true)}>
            {siteInfo?.accountDisabled ? 'Enable account' : 'Disable account' }
        </Button>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  mainPaper: {
    marginTop: '20px'
  },
  emailChangeInfo: {
    width: '100%',
    fontSize: '60%'
  },
  deleteAccountPaper: {
    marginTop: '20px',
    padding: '10px'
  },
  deleteAccountButton: {
    backgroundColor: '#EE0000FF',
    display: 'flex',
    margin: 'auto',

    "&:hover": {
      backgroundColor: '#FF0000AA',
    }
  }
}));