import React from 'react';

import { makeStyles, Typography, Paper,
    Table, TableBody, TableHead, TableCell, TableContainer, TableRow } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2em',
    fontWeight: '600',
    margin: '20px 0px 10px'
  },
  tableContainer: {
    maxWidth: '600px',
    margin: '20px 0px',

    '& th': {
      fontWeight: '600'
    }
  },
  paper: {
    marginTop: '20px',
    padding: '15px'
  },
  subscriptionStatus: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '1.2em'
  },
  active: {
    color: 'green'
  },
  notActive: {
    color: 'red'
  },
  invoiceStatus: {
    textTransform: 'uppercase',
    fontWeight: '600'
  },
  invoiceLink: {
    textDecoration: 'none',
    fontWeight: '600',
    color: '#0FD1B0'
  }
}));

export const SubscriptionOverview = ({customerInfo, subscription}) => {
  const classes = useStyles();

  if (!subscription) {
    subscription = customerInfo?.customer?.subscriptions.data[0];
  } else {
    subscription = subscription.subscription;
  }
  var currency = customerInfo?.customer?.currency ? customerInfo?.customer?.currency : customerInfo.priceDetails.currency;

  return (
   <Paper className={classes.paper}>
      <Typography
          variant="h3"
          color="inherit"
          className={classes.title}
          id="subscription">
        Your subscription
      </Typography>
      <div className={classes.tableContainer}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow key="email">
                <TableCell component="th" scope="row">Email</TableCell>
                <TableCell align="right">{customerInfo.customer.email}</TableCell>
              </TableRow>
              <TableRow key="subscription-status">
                <TableCell component="th" scope="row">Subscription status</TableCell>
                <TableCell align="right">
                  <span className={classnames(classes.subscriptionStatus, subscription.status === 'active' ? classes.active : classes.notActive )}>
                    {subscription.status}
                  </span>
                </TableCell>
              </TableRow>
              { subscription.items.data[0].plan.amount ?
                <TableRow key="subscription-status">
                  <TableCell component="th" scope="row">Subscription price</TableCell>
                  <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(subscription.items.data[0].plan.amount/100)} / month</TableCell>
                </TableRow> : null }
              <TableRow key="started">
                <TableCell component="th" scope="row">Started</TableCell>
                <TableCell align="right">{new Intl.DateTimeFormat("en-GB", {
                         dateStyle: 'medium',
                         timeStyle: 'short'
                      }).format(new Date(0).setUTCSeconds(subscription.created))}</TableCell>
              </TableRow>
              <TableRow key="current-period">
                <TableCell component="th" scope="row">Current period</TableCell>
                <TableCell align="right">{new Intl.DateTimeFormat("en-GB", {
                         dateStyle: 'medium'
                      }).format(new Date(0).setUTCSeconds(subscription.current_period_start))}
                      &nbsp;-&nbsp;
                      { new Intl.DateTimeFormat("en-GB", {
                           dateStyle: 'medium'
                        }).format(new Date(0).setUTCSeconds(subscription.current_period_end))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.structureTitle}>
        <Typography
            variant="h3"
            color="inherit"
            className={classes.title}
            id="structure">
          Latest 5 invoices
        </Typography>
      </div>
      <div className={classes.tableContainer}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow key="invoice-id">
                <TableCell component="th">Invoice date</TableCell>
                <TableCell component="th">Invoice number</TableCell>
                <TableCell component="th" align="right">Invoice amount</TableCell>
                <TableCell component="th" align="right">Invoice status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { customerInfo.lastInvoices?.data.map(invoice =>
                  <TableRow key="invoice-id">
                    <TableCell align="left">{new Intl.DateTimeFormat("en-GB", {
                                                                      dateStyle: 'medium'
                                                                   }).format(new Date(0).setUTCSeconds(invoice.created))}</TableCell>
                    <TableCell align="left"><a className={classes.invoiceLink} href={invoice.hosted_invoice_url}>{invoice.number}</a></TableCell>
                    <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice.currency }).format(invoice.amount_due / 100)}</TableCell>
                    <TableCell align="right" className={classes.invoiceStatus}>{invoice.status}</TableCell>
                  </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
}
