export async function setSite(siteKey, redirectPath, notify) {
      if (siteKey === "ADMIN") {
        siteKey = '';
      }

      const request = new Request('/backend/rest/user/site', {
          method: 'POST',
          body: `siteKey=${siteKey}`,
          headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      });
      await fetch(request)
        .then(response => {
            if (response.ok) {
              return response.text();
            } else {
              return Promise.reject(response.statusText);
            }
        })
        .then(realSiteKey => {
            console.log('realSiteKey', realSiteKey);
            sessionStorage.setItem('siteKey', realSiteKey);
            window.location=redirectPath;
        })
        .catch(error => {
          notify('Failed to set active site: ' + error, { type: 'error' });
          console.log(error);
        });
};

export function getSites(setFunction, notify) {
      const request = new Request('/backend/rest/user/site', {
          method: 'GET'
      });
      fetch(request)
          .then(response => {
              return response.ok ? response.json() : Promise.reject(response.statusText);
          })
          .then(response => {
            setFunction(response);
          })
          .catch(error => {
            notify('Failed to get sites: ' + error, { type: 'error' });
            console.log(error);
          });
  };
