export const updateArticle = (form, record, notify) => {
  fetch("/backend/rest/scraper/list/" + record.id + "/refresh")
    .then(response => {
        return response.ok && response.status !== 204 ? response.json() : Promise.reject(response.statusText);
    })
    .then(refreshedPage => {
        form.change('title', refreshedPage.title);
        form.change('description', refreshedPage.description);
        form.change('author', refreshedPage.author);
        form.change('publishTime', refreshedPage.publishTime);
        form.change('content', refreshedPage.content);
        form.change('richContent', refreshedPage.richContent);
        form.change('characters', refreshedPage.characters);
        notify('Updated page!');
    })
    .catch(error => {
      notify('Failed to update page: ' + error, { type: 'error' });
      console.log(error);
    });
}

export const compensateTimezone = v => {
  var date = new Date(v)
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset()) // JS wtf
  return date.toISOString().replace("Z", "")
};
