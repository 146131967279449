import React, { useState, Fragment } from 'react';

import { makeStyles, Typography, Button, CircularProgress,
  FormControl, FormControlLabel, RadioGroup, Radio, Paper, Input, InputLabel } from '@material-ui/core';

import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

import PaymentTierOverview from './PaymentTierOverview';
import { createSubscription } from './PaymentUtil';

export const PaymentInput = ({ subscriptionCompleteCallback, customerInfo, usage }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [couponCode, setCouponCode] = useState();

  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [processing, setProcessing] = useState(false);
  const [cardSelection, setCardSelection] = useState(customerInfo?.paymentMethod?.card ? 'existing' : 'new');
  const [paymentButtonEnabled, setPaymentButtonEnabled] = useState(true);

  const errorHandler = (message) => {
    setProcessing(false);
    setMessage(null);
    setError('❌ ' + message);
    setPaymentButtonEnabled(true);
  }

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError('❌ ' + event.error.message);
    } else {
      setError(null);
    }
  }

  const onSubscriptionComplete = (result) => {
    setError(null);
    setProcessing(false);
    setMessage("✅ Payment Succeeded!");
    subscriptionCompleteCallback(result);
  }

  const processPayment = async () => {
    setPaymentButtonEnabled(false);
    setProcessing(true);
    if (customerInfo?.paymentMethod?.card && cardSelection === 'existing') {
      paymentOk(customerInfo.paymentMethod);
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log('[error]', error);
        setPaymentButtonEnabled(true);
        setProcessing(false);
      } else {
        paymentOk(paymentMethod);
      }
    }
  }

  const paymentOk = (paymentMethod) => {
      console.log('Payment OK!');
      createSubscription(stripe, customerInfo.customer.id, paymentMethod, customerInfo.subscriptionType, couponCode, onSubscriptionComplete, errorHandler);
  }

  const createExistingCardLabel = (classes) => (
    <div>Use existing card ending in <b>{customerInfo.paymentMethod.card.last4}</b> expiring <b>{customerInfo.paymentMethod.card.exp_month}/{customerInfo.paymentMethod.card.exp_year}</b></div>
  )

  const createNewCardLabel = (classes) => (
    <Fragment><div>New card: </div><CardInput handleChangeHook={handleChange} /></Fragment>
  )

  return (
    <div>
      { customerInfo && customerInfo?.priceDetails?.tiers
            ? <PaymentTierOverview tiers={customerInfo.priceDetails.tiers}
                                    currency={customerInfo.priceDetails.currency}
                                    usage={usage}/> : null }

      <Typography
          variant="h5"
          color="inherit"
          className={classes.text}
          id="payment-text">
        Enter your payment details:
      </Typography>

      { customerInfo?.paymentMethod?.card ?
           <FormControl component="fieldset" className={classes.cardOptionContainer}>
              <RadioGroup aria-label="card-option" name="card-option" value={cardSelection}>
                <Paper className={classes.cardOptionPaper}>
                  <FormControlLabel className={classes.formControlLabel} value="existing" control={<Radio color="primary" />}
                    label={ createExistingCardLabel(classes)} onClick={() => setCardSelection('existing')}/>
                </Paper>
                <Paper className={classes.cardOptionPaper}>
                  <FormControlLabel className={classes.formControlLabel} value="new" control={<Radio color="primary" />}
                    label={ createNewCardLabel(classes)} onClick={() => setCardSelection('new')}/>
                </Paper>
              </RadioGroup>
           </FormControl>
          : <CardInput handleChangeHook={handleChange} /> }
        <FormControl required fullWidth margin="normal">
          <InputLabel htmlFor="couponCode" className={classes.labels}>
            Discount Code
          </InputLabel>
          <Input
            name="couponCode"
            type="couponCode"
            autoComplete="couponCode"
            className={classes.inputs}
            disableUnderline={true}
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </FormControl>
      <div className={classes.cardError}>{error}</div>
      <div className={classes.cardMessage}>{message}</div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!stripe || !paymentButtonEnabled}
          onClick={processPayment}>
          Make payment
        </Button>
        {!stripe || processing ? <CircularProgress className={classes.circularProgress} size={30}/> : null}
      </div>
    </div>
  );
}

const CardInput = (handleChangeHook) => {
  const classes = useStyles();

  return (
      <CardElement
        id="card-element"
        options={CARD_ELEMENT_OPTIONS}
        onChange={handleChangeHook}
        className={classes.cardElement}
      />
  );
}

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  hidePostalCode: true
};

const useStyles = makeStyles(theme => ({
    button: {
      margin: '15px',
      verticalAlign: 'top'
    },
    cardElement: {
      width: '400px',
      margin: '5px 0px'
    },
    cardError: {
      color: 'red',
      margin: '15px'
    },
    cardMessage: {
      color: 'green',
      fontWeight: '600',
      margin: '15px'
    },
    formControlLabel: {
      width: '100%',
    },
    cardOptionPaper: {
      padding: '10px',
      margin: '10px'
    },
    cardOptionContainer: {
      width: '100%'
    },
    circularProgress: {
      margin: '18px'
    }
}));