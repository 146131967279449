import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles, LinearProgress } from '@material-ui/core';
import classnames from 'classnames';

import { getWordsUsed } from './usageUtil.js';

export const WordCount = ({update, delay = 5000, numAttempts = 12, updateCallback }) => {
  const classes = useStyles();

  const [wordCount, setWordCount] = useState();
  const [version, setVersion] = useState(0);
  // Used to poll for a change, max numAttempts times, with specified delay
  const [attempts, setAttempts] = useState(numAttempts);

  const updateWordCount = useCallback(() => {
     getWordsUsed().then(data => {
      if (data?.version === version && attempts > 0) {
        // Data is the same, try again
        setAttempts(attempts - 1);
      } else {
        // Data has changed? Ok we're done
        setAttempts(0);
        sessionStorage.setItem('wordCountHint', 0);
        setWordCount(data);
        setVersion(data?.version)
        if (updateCallback) {
          updateCallback(data);
        }
      }
    });
  }, [attempts, version, updateCallback]);

  useEffect(() => {
    var timeOutId = 0;
    if (!wordCount) {
      updateWordCount();
    } else if (attempts > 0) {
      timeOutId = setTimeout(() => {
        updateWordCount();
      }, delay);
    }

    return function cleanup() {
      if (timeOutId) clearTimeout(timeOutId);
    }
  }, [attempts, delay, updateWordCount, wordCount]);

  useEffect(() => {
    setWordCount(wordCount); // Trigger component update
    setAttempts(numAttempts);
  }, [update, wordCount, numAttempts]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  // You can put the consumed characters in a localSession property 'wordCountHint' to get a quicker feedback
  const getCurrentWordCount = () => {
    var hintedAmount = sessionStorage.getItem('wordCountHint') ? parseInt(sessionStorage.getItem('wordCountHint')) : 0;
    var calculatedAmount = 0;
    if (wordCount) {
      calculatedAmount = wordCount?.current + hintedAmount;
    } else {
      calculatedAmount = hintedAmount;
    }

    if (wordCount?.limit && calculatedAmount > wordCount?.limit) {
      calculatedAmount = wordCount?.limit;
    }

    return calculatedAmount;
  }

  return (
    <div>
      <div className={classes.text}>{formatNumber(getCurrentWordCount())} words used { wordCount?.limit > 0 ? ('out of ' + formatNumber(wordCount.limit)) : null }</div>
      <LinearProgress variant="determinate" value={ wordCount?.limit > 0 && getCurrentWordCount() < wordCount?.limit ? (getCurrentWordCount() * 100 / wordCount.limit) : 0 }
        classes={{colorPrimary: classnames({
            [classes.progressBarExceeded]: wordCount?.limit > 0 && getCurrentWordCount() >= wordCount?.limit
          })}}/>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'center',
    width: '100%',
    margin: '5px',
    fontWeight: 'bold'
  },
  progressBarExceeded: {
    backgroundColor: 'red'
  }
}));