import React from 'react';
import { useRedirect, Edit, TextInput, SimpleForm } from 'react-admin';

import { makeStyles, Button, Typography } from '@material-ui/core';

import classnames from 'classnames';

import { continueButtonStyle } from '../style/ContinueButtonStyle.js'
import { mainContainerStyle } from '../style/mainContainerStyle';

export const PrePostRoll = ({endPoint, basePath, resource, title, ...props}) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const mainContainerClasses = mainContainerStyle();

  const continueClicked = () => {
    redirect("/scraper/list");
  }

  return (
    <div className={classnames(mainContainerClasses.mainContainer, classes.innerContainer)}>
      <div>
        <Typography variant="h6" className={classes.headerText}>
            Enter the pre-roll or post-roll text you would like to hear before or after the main article content.
            Pre- and post-roll creation must be <i><u>setup prior to audio-file creation</u></i>.
            If you change the pre/post roll messages <i><u>after audio file creation</u></i>,
            simply re-create the audiofiles in the articles page.
        </Typography>
        <Edit title="Pre/post roll" id="dummy" basePath={basePath} resource={resource} mutationMode="pessimistic" className={classes.prePostRollEdit}>
            <SimpleForm>
                <TextInput source="preRoll" fullWidth multiline/>
                <TextInput source="postRoll" fullWidth multiline/>
            </SimpleForm>
        </Edit>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={classes.continueButton}
          onClick={continueClicked}>
          Continue ...
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  innerContainer: {
    padding: '15px',
    maxWidth: '1000px',
    margin: '20px auto'
  },
  continueButton: continueButtonStyle,
}));

