export const nameRegex = /^[a-zA-Z0-9 .]{3,}$/
export const usernameRegex = /^[a-z0-9-_+.@]{3,}$/
export const passwordRegex = /^.{3,}$/

export const validatePassword = (values) => {
    const errors = {};
    if (!values.newPassword || !values.newPassword.match(passwordRegex)) {
        errors.newPassword = ['Please fill in the password!'];
    } else if (!values.newPasswordConfirm || !values.newPasswordConfirm.match(passwordRegex)) {
        errors.newPasswordConfirm = ['Please confirm the password!'];
    } else if (values.newPassword !== values.newPasswordConfirm) {
        errors.newPasswordConfirm = ['The passwords do not match!'];
    }
    return errors
}

export const validatePasswordUpdate = (values) => {
    if (values.password || values.newPassword || values.newPasswordConfirm) {
        const errors = validatePassword(values);
        if (sessionStorage.getItem('siteKey') && (!values.password || !values.password.match(passwordRegex))) {
            errors.password = ['Enter your current password!'];
        }
        return errors
    }
    return {};
}

export const validateRoleChoice = (value) => {
    if (!value[0]) {
      return 'Must pick a role!';
    }
    return undefined;
}