import React from 'react';
import { useNotify } from 'react-admin';
import { makeStyles, Button } from '@material-ui/core';

import * as SitesUtil from './SitesUtil'
import { hasRole } from './authProvider';

const useStyles = makeStyles({
    adminButton: {
        backgroundColor: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        borderRadius: '8px'
    }
});

const SiteSelect = props => {
  const classes = useStyles();
  const notify = useNotify();

  // When logging in as ADMIN into the regular selfserve UI -> redirect to ADMIN ui
  var redirectToAdmin = sessionStorage.getItem('loggedIn') === 'true'
    && !window.location.pathname.startsWith('/admin/')
    && !sessionStorage.getItem('siteKey');
  if (redirectToAdmin) {
    console.log("Redirecting to admin UI");
    setTimeout(() => {
      notify('You are logged in as ADMIN, but you logged into the selfserve user UI! Redirecting you to the ADMIN UI!', { type: 'warning' });
      window.location = '/admin/';
     }, 1000);
  }

  // When logging in as multi-account user into admin -> redirect to sites page
  var redirectToSiteSelect = sessionStorage.getItem('loggedIn') === 'true'
    && window.location.pathname.startsWith('/admin/')
    && sessionStorage.getItem('siteKey')
    && !hasRole('ADMIN');
  if (redirectToSiteSelect) {
    console.log("Redirecting to multi-account sites select");
    setTimeout(() => {
      window.location = '/admin/#/siteconfig/';
     }, 1000);
  }

  const handleSelect = (event) => {
    SitesUtil.setSite("ADMIN", '/admin/#/siteconfig/', notify);
  }

  return <Button className={classes.adminButton} variant="contained" color="secondary"
            onClick={handleSelect}>{sessionStorage.getItem('siteKey') ? sessionStorage.getItem('siteKey') : "ADMIN"}</Button>;
}

export default SiteSelect;