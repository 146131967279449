import React, { useState, useEffect } from 'react';
import { useNotify, useRedirect, Loading, Title, Confirm } from 'react-admin';

import { Paper, makeStyles, MenuItem, Select, Button, Typography } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';

import classnames from 'classnames';

import Empty from '../../../util/Empty';

import PodcastPreview from './PodcastPreview'

import { determinePodcastStatus } from '../../../util/PodcastUtil';

import { continueButtonStyle } from '../../style/ContinueButtonStyle.js'

const PodcastPreviewPage = (endPoint, title) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const [selectedPodcast, setSelectedPodcast] = useState();
  const [podcasts, setPodcasts] = useState();

  useEffect(() => {
      fetch('/backend/rest/podcast-publish')
        .then(response => {
          if (!response.ok) {
            notify("Could not get podcasts!", { type: 'error' });
            return Promise.reject();
          }
          return response.json();
        })
        .then(json => {
          if (json.content.length > 0) {
            setSelectedPodcast(json.content[0]);
          }
          setPodcasts(json.content);
        });
  }, [notify]);

  const podcastSelected = (e) => {
    var id = e.target.value;
    setSelectedPodcast(podcasts.filter(p => p.id === id)[0]);
  }

  if (!podcasts) {
    return (<Loading loadingPrimary="Loading podcast preview" loadingSecondary="Please wait..." />);
  } else if (podcasts.length === 0) {
    const title = "No podcast!";
    const message = [
      <p>Create a podcast in the <button onClick={() => redirect("/selfserve/podcast")}>Setup podcast</button> menu first.</p>
    ];

    return <Empty title={title} message={message} hasCreate={false}/>;
  }

  return (
    <div className={classes.mainContainer}>
      <Title title="Podcast distribution" />
      <Typography variant="h3"  className={classes.title}>Podcast preview</Typography>

      <div className={classes.flexContainer}>
        <div>
          <div className={classes.publishSection}>
            <Paper className={classes.infoTextPaper}>
              <p className={classes.headerText}>
                  Here you can see a preview of how the podcast will appear in iTunes, Google and Spotify.
                  To edit the podcast go back to the podcast setup page.
                  Please approve each podcast series for distribution by pressing the <b>Publish</b> button at the bottom of the page.
                  If you have a podcast series then select from the dropdown list below.
              </p>
              <br/>
              <p className={classes.headerText}>
                  In order to add new episodes to the podcast, either manually add them by going to
                  the <button onClick={() => redirect("/scraper/list")}>articles page</button> and
                  creating audiofiles for the articles you want to publish, or turn on <b>automatic publication</b> on the same page
                  in order to automatically add newly published articles to the podcast.
              </p>
            </Paper>
            <PublishButton className={classes.publishButton} selectedPodcast={selectedPodcast}/>
          </div>
          <div className={classes.podcastPreviewContainer}>
            { podcasts?.length > 1 ?
                <Select
                  id="select-podcast"
                  value={selectedPodcast?.id}
                  variant="outlined"
                  className={classes.podcastSelect}
                  onChange={ podcastSelected }
                >
                  { podcasts.map(podcast => <MenuItem key={podcast.id} value={podcast.id}>{podcast.podcastName} - {podcast.episodeCount} episodes</MenuItem>) }
                </Select> : null }
            <PodcastPreview podcastId={selectedPodcast?.id}/>
          </div>
          <PublishButton className={classes.publishButton} selectedPodcast={selectedPodcast} />
        </div>

      </div>
    </div>
  );
}

const PublishButton = ({selectedPodcast}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [confirmPublishOpen, setConfirmPublishOpen] = useState(false);

  var podcastStatus = determinePodcastStatus(selectedPodcast);

  const publishButtonClicked = (e) => {
    var action = podcastStatus.action;

    fetch('/backend/rest/selfserve/podcast-publish/' + selectedPodcast.id + '/' + action, {
        method: 'PUT'
      })
      .then(response => {
        if (!response.ok) {
          notify("Could not publish or unpublish podcast!", { type: 'error' });
          return Promise.reject();
        }
        return Promise.resolve();
      })
      .then(ignored => window.location.reload());
  }

  const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
  const content = podcastStatus.action === 'publish'
                                      ? `If you press confirm below, we will submit your podcast to Spotify, Apple and Google.
                                        Publishing can take up to 2 days. Once you do this, you will be unable to stop the process.
                                        However, once the podcast is published, you can request to unpublish the podcast again.`
                                      : `If you press confirm below, we will unpublish your podcast from Spotify, Apple and Google.
                                        This can take up to 5 days. Note that episodes that have already been downloaded to user devices cannot be deleted.`;

  return (
    <div>
        <Confirm
            isOpen={confirmPublishOpen}
            title={capitalizeFirstLetter(podcastStatus.action) + " podcast"}
            content={content}
            confirm={capitalizeFirstLetter(podcastStatus.action) + " podcast"}
            confirmColor="primary"
            ConfirmIcon={CheckIcon}
            CancelIcon={CancelIcon}
            cancel="Cancel"
            onConfirm={() => { setConfirmPublishOpen(false); publishButtonClicked(); }}
            onClose={() => { setConfirmPublishOpen(false); }}
        />
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classnames(classes.button, {
              [classes.buttonEnabled]: podcastStatus.action,
              [classes.buttonDisabled]: !podcastStatus.action,
          })}
          disabled={!podcastStatus.action}
          onClick={() => setConfirmPublishOpen(true)}>
          { podcastStatus.text }
        </Button>
        { podcastStatus.state === 'inprogress'
            ? <p className={classes.publishingInfo}>
                  The podcast will be published as soon as possible. This may take up to 3 working days.
                  We will notify you by email when the podcast gets published.
              </p> : null }
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: '40px',
    textAlign: 'center',
    alignSelf: 'center',
    paddingRight: '200px'
  },
  flexContainer: {
    maxWidth: '800px',
  },
  title: {
    marginBottom: '20px'
  },
  podcastPreviewContainer: {
    maxWidth: '1200px',
    minWidth: '800px',
    margin: '10px',
    flexBasis: '3 3 70%',
  },
  podcastSelect: {
    width: '80%',
    height: '50px',
    margin: '10px'
  },
  infoTextPaper: {
    padding: '15px',
    margin: '10px',
    height: 'fit-content'
  },
  button: {
    ...continueButtonStyle,
    marginTop: '10px',
  },
  buttonEnabled: {
    '& span': {
      color: 'white'
    }
  },
  buttonDisabled: {
    backgroundColor: 'lightgrey',

    '& span': {
      color: 'darkgrey'
    }
  },
  headerText: {
    textAlign: 'left',
    fontSize: '18px',
    margin: '0px'
  },
  publishingInfo: {
    fontSize: '120%'
  }
}));

export default PodcastPreviewPage;
