import React from 'react';
import { useQueryWithStore, useRedirect, useNotify, Loading } from 'react-admin';

import { hasPermissionSessionStorage } from '../../../util/authProvider';

import ListPodcasts from './ListPodcasts';
import { PodcastCreate, PodcastEdit } from './PodcastCreateEdit';

import { mainContainerStyle } from '../../style/mainContainerStyle';

export const PodcastCreateOrEdit = ({...props}) => {
  const mainContainerClass = mainContainerStyle();

  const redirect = useRedirect();
  const notify = useNotify();

  const  { data } = useQueryWithStore({
      type: 'getList',
      resource: 'selfserve/podcast',
      payload: {
       pagination: { page: 1, perPage: 10 },
       sort: { field: 'id', order: 'ASC' },
       filter: {}
     }
  });

  if (!data) {
    return <Loading/>;
  } else if (data.length === 0) {
    const onSuccess = () => {
        notify("Podcast created!");
        redirect("/podcast-preview");
    };

    return (
      <PodcastCreate onSuccess={onSuccess} {...props}/>
    );
  } else if (data.length === 1 && !hasPermissionSessionStorage("MultiplePodcasts")) {
    const onSuccess = () => {
        notify("Podcast updated!");
        redirect("/podcast-preview");
    };

    return (
      <PodcastEdit onSuccess={onSuccess} id={data[0].id} {...props}/>
    );
  } else {
    return (
      <div className={mainContainerClass.mainContainer}>
        <ListPodcasts {...props}/>
      </div>
    );
  }
}

