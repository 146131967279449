import React from 'react';

import { makeStyles, Button, Typography } from '@material-ui/core';
import { useNotify, useRedirect, useRefresh, List, Datagrid, TextField } from 'react-admin';
import classnames from 'classnames';
import Empty from '../../util/Empty';

import { hasPermissionSessionStorage } from '../../util/authProvider';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import { continueButtonStyle } from '../style/ContinueButtonStyle.js'
import { mainContainerStyle } from '../style/mainContainerStyle';

const SectionEnabledInput = ({ record = {}, source }) => {
  const classes = useStyles();

  const notify = useNotify();
  const refresh = useRefresh();

  const sectionClicked = (e) => {
    fetch('/backend/rest/selfserve/sections/' + record["id"],
            { method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: !record[source]
            })
      .then(response => {
        if (!response.ok) {
          notify("Could not set section state!", { type: 'error' });
          return Promise.reject();
        }
        return response.text();
      })
      .then(ignored => {
        refresh();
      });

    e.stopPropagation();
  }

  return (
    <Button
      className={classnames(classes.sectionButton, {[classes.enabledSection]: record[source], [classes.disabledSection]: !record[source]})}
      onClick={sectionClicked} startIcon={record[source] ? <DoneIcon/> : <ClearIcon/> }>
      {record[source] ? "Selected" : "Not selected"}
    </Button>
  );
}

const CustomEmpty = () => {
  const title = "We did not find any suitable content on your website!";
  const message = [
    <p>If you believe you have suitable content on your website, please contact us at <a href="mailto:support@audio-harvest.com">support@audio-harvest.com</a> and we'll help you further!</p>,
  ];

  return <Empty title={title} message={message} hasCreate={false}/>;
}

export const ListSections = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();

  const downloadSectionArticles = () => {
    fetch('/backend/rest/selfserve/triggerCrawl')
      .then(response => {
        if (!response.ok) {
          notify("Could not trigger crawl!", { type: 'error' });
          return Promise.reject();
        }
        return response.text();
      })
      .then(ignored => {
        if (hasPermissionSessionStorage('PrePostRoll')) {
          redirect("/selfserve/pre-post-roll");
        } else {
          redirect("/scraper/list");
        }
      });
  }

  return (
    <div className={mainContainerStyle().mainContainer}>
      <Typography variant="h6" className={classes.headerText}>
        Select the site sections with suitable content for a podcast from the list below.
      </Typography>
      <List title="Sections" empty={<CustomEmpty/>} sort={{ field: 'enabled', order: 'DESC' }}
        bulkActionButtons={false} actions={false} filter={null} exporter={false} {...props}>
          <Datagrid>
              <TextField source="name" className={classes.sectionName}/>
              <SectionEnabledInput source="enabled" label="Choose sections"/>
          </Datagrid>
      </List>
      <div>
        <Button
          className={classes.continueButton}
          onClick={downloadSectionArticles}>
          Save section selection
        </Button>
        <Typography variant="h6" className={classes.footerText}>
          Switching from enabled back to disabled, post articles being added, means that no new articles from that section will be added.<br/>
          If a user wants to remove enabled articles they must do that in the Articles Section.
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  sectionName: {
    textTransform: 'capitalize',
    fontSize: '110%'
  },
  sectionButton: {
    backgroundColor: '#0FD1B0',
    width: '160px',

    "& svg": {
      color: 'white'
    }
  },
  enabledSection: {
  },
  disabledSection: {
    backgroundColor: 'lightgrey !important'
  },
  headerText: {
    margin: '20px 0px',
  },
  footerText: {
    margin: '20px',
    fontSize: '14px',
    fontStyle: 'italic'
  },
  continueButton: continueButtonStyle
}));

export default ListSections;