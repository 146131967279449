import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AudioFilePreview } from './AudioFilePreview';

const ArticlePanel = ({ id, record, resource }) => {
  const classes = useStyles();

  return (
    <div className={classes.articleContainer}>
      <div className={classes.leftSpacer}/>
      <div className={classes.rightContainer}>
        <h3 className={classes.articleTitle}>
          <a className={classes.articleTitleLink} href={record.url} target="_blank" rel="noopener noreferrer">{record.title}</a>
        </h3>
        <table className={classes.articlePropertyTable}>
          <tbody>
          { record.author ?
            <tr>
              <td className={classes.tableHeader}>
                <h4 className={classes.articleProperty}>Author:</h4>
              </td>
              <td>{record.author}</td>
            </tr>
             : null }
            { record.description ?
            <tr>
              <td className={classes.tableHeader}>
                <h4 className={classes.articleProperty}>Description:</h4>
              </td>
              <td>{record.description}</td>
            </tr> : null }
            { record.publishTime ?
              <tr>
                <td className={classes.tableHeader}>
                   <h4 className={classes.articleProperty}>Publish time:</h4>
                </td>
                <td>{new Intl.DateTimeFormat("en-GB", {
                                              dateStyle: 'short',
                                              timeStyle: 'medium'
                                           }).format(Date.parse(record.publishTime))}</td>
              </tr> : null }
            <tr>
              <td className={classes.tableHeader}>
                <h4 className={classes.articleProperty}>Section:</h4>
              </td>
              <td title={record.keywords} className={classes.sectionName}>{record.section}</td>
            </tr>
          </tbody>
        </table>
        <AudioFilePreview record={record}/>
        <div className={classes.articleContent}>
        {record.content.split("\n").map((s, index) => <p key={index}>{s}</p>)}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  articleContainer: {
    display: 'flex'
  },
  leftSpacer: {
    width: '100%',
    maxWidth: '80px',
  },
  rightContainer: {
    width: '100%',
    maxWidth: '600px',
    marginRight: 'auto'
  },
  articleTitle: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '140%',
    padding: '5px 0px !important'
  },
  articleTitleLink: {
    textDecoration: 'none',
    color: 'black',
  },
  articlePropertyTable: {
    borderSpacing: '0px',
    backgroundColor: '#f5f5f5',
    borderRadius: '3px',
    padding: '5px'
  },
  articleProperty: {
    margin: '0px'
  },
  articleContent: {
    "& p": {
      margin: "15px 0px"
    }
  },
  tableHeader: {
    width: '100px',
    verticalAlign: 'top'
  },
  sectionName: {
    '&:hover $keywordsSpan': {
      visibility: 'visible'
    }
  },
  keywordsSpan: {
    visibility: 'hidden',
  },
}));

export default ArticlePanel;