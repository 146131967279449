import { makeStyles } from '@material-ui/core';

export const tableStyles = makeStyles(theme => ({
  mainContainer: {
    width: 'calc(100% - 2*20px - 2*10px)',
    padding: '10px'
  },
  table: {
    borderStyle: 'none',
    padding: '20px',

    "& tr": {
      borderBottomStyle: 'dashed',
      borderWidth: '2px',
      borderColor: '#00000022'
    },
    "& tbody tr:last-child": {
      borderBottomStyle: 'hidden'
    }
  },
  title: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '10px'
  },
  summaryrow: {
    color: '#0FD1B0',
    fontWeight: 'bold',
  },
  normalRow: {
    color: 'grey',
    fontWeight: 'bold',
  },
  extraInfo: {
    textAlign: 'left',
    width: '500px',
    margin: '20px auto',
    fontSize: '12px',
    fontStyle: 'italic'
  }
}));

export const useStyles = makeStyles(theme => ({
  header: {
    textAlign: 'center',
    margin: '20px auto'
  },
  title: {
    display: 'inline'
  },
  graphContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '800px',
    margin: 'auto'
  },
  graph: {
    width: '100%'
  },
  doughnut: {
    width: '50%',
  }
}));

export const infoBoxStyles = makeStyles(theme => ({
  graphPaper: {
    margin: '10px',
    textAlign: 'center',
    padding: '114px 0px',
  },
  graphPaper2: {
    margin: '10px',
    textAlign: 'center',
    padding: '115px 0px 85px',
  },
  headerText: {
    textAlign: 'center'
  },
  doughnutNumber: {
    color: '#0FD1B0',
    fontWeight: 'bold',
    fontSize: '40px'
  },
  downloadsText: {
    color: '#aaaaaa',
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: '40px'
  },
  viewsNumber: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  viewsText: {
    color: '#aaaaaa',
    fontWeight: 'bold',
    fontSize: '20px'
  }
}));