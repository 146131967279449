import React, { forwardRef, Fragment } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import SiteSelect from './SiteSelect';
import { hasPermissionSessionStorage, hasRole } from '../util/authProvider';
import { CrawlStatus } from '../selfserve/util/CrawlStatus';

import SettingsIcon from '@material-ui/icons/Settings';

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <Fragment>
    <MenuItemLink
        ref={ref}
        to="/user-profile"
        primaryText="User profile"
        leftIcon={<SettingsIcon />}
        onClick={onClick} // close the menu on click
    />
    <hr/>
  </Fragment>
));

const AHUserMenu = props => (
    <UserMenu {...props}>
        { hasPermissionSessionStorage('UserMenu') ? <ConfigurationMenu /> : null }
    </UserMenu>
);

export const AudioHarvestAppBar = props => {
    const classes = useStyles();

    const contextMenuClicked = (e) => {
      if (hasRole('ADMIN')) {
        window.location.pathname='/admin/#/siteconfig';
      }
      e.stopPropagation();
    }

    return (
        <AppBar className={classes.appBar} {...props} color="primary"
            userMenu={<AHUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <div className={classes.spacer}/>
            <div className={classes.logoContainer} onContextMenu={contextMenuClicked} >
              <img src="/ah_logo.png" alt="AudioHarvest Logo" className={classes.logo}/>
            </div>
            <div className={classes.spacer}/>
            { sessionStorage.getItem('siteKey') && (!window.location.pathname.startsWith('/admin/') || hasRole('ADMIN'))
              ? <div className={classes.crawlStatus}>
                  <CrawlStatus/>
                </div>
              : null
            }
            { hasPermissionSessionStorage('SwitchSites')
                ? <SiteSelect/>
                : <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.siteKey}>
                       {sessionStorage.getItem('siteKey')}
                   </Typography>}
        </AppBar>
    );
};

const useStyles = makeStyles({
    title: {
        flex: '2 2 auto',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logoContainer: {
      flex: '2 2 auto',
      margin: '10px'
    },
    spacer: {
        flex: '1 0 auto'
    },
    logo: {
      height: '40px',
    },
    crawlStatus: {
      flex: '1 1 auto',
      textAlign: 'center'
    },
    siteKey: {
      textAlign: 'right'
    },
    appBar: {
    }
});