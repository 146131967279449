import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider, AutocompleteArrayInput } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
}));

export const SectionSelectorInput = ({record, validate, ...props}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [sections, setSections] = useState();

  const dataProvider = useDataProvider();

  useEffect(() => {
    var url = '/backend/rest/scraper/sections';
    if (record?.siteKey) {
      url += `?siteKeyHint=${record.siteKey}`;
    }
    fetch(url)
      .then(response => {
          return response.ok ? response.json() : Promise.reject(response.statusText);
      })
      .then(data => {
          return data
            .map((entry) => {
              var item = {};
              item.id = entry;
              item.name = entry;
              return item;
            });
      })
      .then((data) => {
        setSections(data);
      })
      .catch(error => {
        notify('Failed to get sections: ' + (error?.message ? error?.message : ''), { type: 'error' });
        console.log(error);
      });
  }, [dataProvider, notify, record]);

  return (
    <>
      <Typography variant="inherit" className={classes.headerText}>
        Select or create a section label for the article to belong to.
      </Typography>
      <AutocompleteArrayInput label="Section" source="sectionsList" choices={sections} fullWidth
          createLabel="<Create new...>" validate={validate}
          onCreate={() => {
              const newTagName = prompt('Enter a new section name');
              if (newTagName) {
                const newTag = { id: newTagName, name: newTagName };
                sections.push(newTag);
                return newTag;
              } else {
                return Promise.reject('No value selected');
              }
          }}
      />
    </>
  );
}
