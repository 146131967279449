import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const httpClient = fetchUtils.fetchJson;

const api = (apiUrl) => {
  const buildQuery = (queryObj, filter) => {
    var query = '';
    for (const [key, value] of Object.entries(queryObj)) {
      if (query !== '') {
        query = query + '&';
      }
      query = query + `${key}=${encodeURIComponent(value)}`;
    }
    for (const [key, value] of Object.entries(filter)) {
      if (query !== '') {
        query = query + '&';
      }
      query = query + `${key}=${encodeURIComponent(value)}`;
    }
    return query;
  }

  const maybeConvertFileUpload = (params) => {
    let ret = Promise.resolve(params);
    if (params?.data?.audioFileUpload && params.data.audioFileUpload?.rawFile instanceof Blob) {
      console.log("Audio file upload!");
      ret = ret.then(params => convertFileToBase64(params.data.audioFileUpload))
        .then(base64 => {
          console.log("Base64 length: ", base64.length);
          params.data.audioFileUpload = base64
          return params;
        });
    }
    if (params?.data?.imageFileUpload && params.data.imageFileUpload?.rawFile instanceof Blob) {
      console.log("Image file upload!");
      ret = ret.then(params => convertFileToBase64(params.data.imageFileUpload))
        .then(base64 => {
          console.log("Base64 length: ", base64.length);
          params.data.imageFileUpload = base64
          return params;
        });
    }
    return ret;
  }

  return {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            page: page-1,
            size: perPage,
            sort: `${field},${order}`,
        };
        if (query.filter && Object.keys(query.filter).length > 0 && query.filter.constructor === Object) {
          query.filter = params.filter;
        }
        const url = `${apiUrl}/${resource}?${buildQuery(query, params.filter)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.content,
            total: json.totalElements,
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
      throw new Error("Not implemented!");
    },

    update: (resource, params) => {
      return maybeConvertFileUpload(params)
        .then(params =>
          httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
              })
          .then(({ json }) => ({ data: json }))
        )
    },

    updateMany: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/many`, {
                method: 'PUT',
                body: JSON.stringify(params),
          })
      .then(({ json }) => ({ data: json }))
    },

    create: (resource, params) =>
      maybeConvertFileUpload(params)
        .then(params =>
          httpClient(`${apiUrl}/${resource}`, {
              method: 'POST',
              body: JSON.stringify(params.data),
          }).then(({ json }) => ({
              data: { ...params.data, id: json.id },
          }))),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map(id => {
            params.id = id;
            return api(apiUrl).delete(resource, params);
          }
        )
      ).then(responses => ({
          data: responses.map(data => data.data)
      })),
  }
};

export default api;

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });