import React, { useState, useRef, Fragment } from 'react';
import { useNotify, ImageInput } from 'react-admin';

import { makeStyles } from '@material-ui/core';

export const PodcastLogoInput = ({ record = {}, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();

  const imageInputRef = useRef(null);

  const [previewUrl, setPreviewUrl] = useState();

  const onDropRejected = (e) => {
    notify("The file was not accepted! Make sure the file is a JPEG or PNG file and the filesize is maximum 5 megabyte!", { type: 'warning' });
  };

  const onDrop = (newFiles, rejectedFiles, event) => {
    if (newFiles.length === 1) {
      setPreviewUrl(URL.createObjectURL(newFiles[0]));
    }
  };

  return (
    <div>
       <img
         title="Podcast image"
         alt="Podcast logo"
         src={previewUrl ? previewUrl : (record.imageUrl ? record.imageUrl : 'https://static.audio-harvest.com/ah-logo-lightgreen.png')}
         className={classes.podcastImage} onClick={(e) => imageInputRef.current.click()}/>
       <ImageInput className={classes.imageUpload} source="imageFileUpload" label="Upload new logo" accept="image/png,image/jpeg" maxSize={5000000}
        placeholder={<p ref={imageInputRef}>Drop a picture to upload, or click to select it.<br/><br/>Podcast images should be square, ideally minimum 1400x1400 pixels and the type should be JPEG or PNG. We will resize the image if necessary, but that may have an impact on the quality.</p>}
        options={{onDrop, onDropRejected}}>
        <Fragment/>
      </ImageInput>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  podcastLogoInputContainer: {
    display: 'flex'
  },
  podcastImage: {
    width: '150px',
    height: '150px',
    margin: '70px 0px 0px 0px',
    borderRadius: '15px',
    cursor: 'pointer'
  },
  imageUpload: {
    margin: '25px 25px 0px',
    width: 'calc(100% - 200px)',

    '& label': {
      fontSize: '170%',
    }
  }
}));