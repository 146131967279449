import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0FD1B0'
    },
    secondary: {
      main: '#04BA9B'
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "Roboto", "Helvetica", "Arial", 'sans-serif'].join(','),
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
    span: {
    }
  },
  overrides: {
    MuiButton: {
      root: {
        color: 'white',
        backgroundColor: '#0FD1B0',
        borderRadius: '20px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',

        "&:hover": {
          boxShadow: 'none',
          backgroundColor: '#04BA9B'
        },

        "&.Mui-disabled": {
          "& span": {
            color: 'rgb(97 97 97 / 35%)',
          }
        }
      },
      label: {
        color: 'white'
      },
      textPrimary: {
        "&:hover": {
          boxShadow: 'none',
          backgroundColor: '#04BA9B'
        }
      },
      contained: {
        boxShadow: 'none',

        "&:hover": {
          boxShadow: 'none',
          backgroundColor: '#04BA9B'
        },
      },
      sizeSmall: {
        margin: '0px 3px'
      }
    },
    RaPaginationActions: {
      actions: {
        margin: '0px 20px'
      },
      button: {
        backgroundColor: '#00000000',

        "&:hover": {
          backgroundColor: '#00000000',
        },

        "& span": {
          color: '#04BA9B',
          fontWeight: 'normal'
        }
      },
      currentPageButton: {
        "& span": {
          color: 'black',
          fontWeight: 'normal'
        }
      }
    },
    RaAppBar: {
      toolbar: {
        color: 'white',
        fontWeight: 'bold',

        "& h6": {
          color: 'white',
          fontWeight: 'bold'
        },

        "& .MuiButton-label": {
          color: 'black'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontWeight: 'bold'
      }
    },
    MuiListItemIcon: {
      root: {
        color: '#0FD1B0'
      }
    },
    MuiStepIcon: {
      text: {
        fill: 'white'
      }
    },
    RaDeleteWithUndoButton: {
      deleteButton: {
        backgroundColor: '#FF000080',
        margin: '0px 5px',
        fontSize: '16px',
        height: '40px'
      }
    },
    RaLayout: {
      root: {
        filter: 'invert',
        backgroundPosition: 'center',
        backgroundImage: 'linear-gradient(0deg, rgba(240, 255, 252, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(240, 255, 252, 1) 100%)'
      }
    }
  }
});