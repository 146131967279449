import React from 'react';
import { makeStyles, Link } from '@material-ui/core';

import { convertPodcastKeyToName } from '../../util/PodcastUtil'

const useStyles = makeStyles(theme => ({
  podcastImage: {
    height: '50px',
    width: '50px',
    borderWidth: '0px',
    borderRadius: '5px',
    margin: '0px',
    boxShadow: '0px 0px 14px 5px #eeeeee'
  },
  episodeImage: {
    height: '40px',
    width: '40px',
    borderWidth: '0px',
    borderRadius: '5px',
    margin: '0px 25px 5px 5px',
    boxShadow: '0px 0px 14px 5px #eeeeee'
  }
}));

export const PodcastLink = ({ record = {}, source, basePath, ...props }) => {

  var podcastLink = record['absoluteLink'];
  if (podcastLink && podcastLink.indexOf('?') < 0) {
    podcastLink += '?html';
  }

  return (
  <Link href={podcastLink} target="_blank" onClick={(e) => e.stopPropagation()} {...props}>
      View podcast
  </Link>);
}

export const PodcastImage = ({ record = {}, source, basePath, ...props }) => {
    const classes = useStyles();

    return (<img
            title="Podcast image"
            alt="Podcast logo"
            src={record.imageUrl ? record.imageUrl : 'https://static.audio-harvest.com/ah-logo-lightgreen.png'}
            className={classes.podcastImage}
            {...props}
        />);
}

export const EpisodeImage = ({ podcast, episode }) => {
    const classes = useStyles();

    let episodeImage = null;
    if (episode.imageUrl) {
      episodeImage = episode.imageUrl;
      console.log("Using episode image");
    } else if (podcast.episodeDefaultImageUrl) {
      episodeImage = podcast.episodeDefaultImageUrl;
      console.log("Using podcast episode default image");
    } else if (podcast.imageUrl) {
      console.log("Using podcast image");
      episodeImage = podcast.imageUrl;
    } else {
      console.log("Using default image");
      episodeImage = 'https://static.audio-harvest.com/ah-logo-lightgreen.png';
    }

    return (<img
            title="Episode image"
            alt="Episode logo"
            src={episodeImage}
            className={classes.episodeImage}
        />);
}

export const CategoryField = ({ record = {}, source, basePath, ...props }) => {
    const classes = useStyles();

    if (record[source]) {
        return (
        <ul className={classes.categoryList} {...props}>
            { record[source].map(it =>
                <li key={it}>{convertPodcastKeyToName(it)}</li>
            )}
        </ul>)
    } else {
        return null;
    }
};
