import { required, minLength } from 'react-admin';

export const validateTitle = [required(), minLength(5)];
export const validateDescription = [required(), minLength(20)];
export const validateContent = [required(), minLength(100)];

export const validateSection = (value, allValues) => {
  if (!value || value.length === 0) {
      return 'Please select or create a section!';
  }
  return undefined;
};

export const validateFileUpload = (value, allValues) => {
  if (!value) {
      return 'Please upload an audio file!';
  }
  return undefined;
};