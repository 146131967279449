import React from 'react';
import { Layout } from 'react-admin';
import { AudioHarvestAppBar } from './AudioHarvestAppBar';
import { AudioHarvestMenu } from './AudioHarvestMenu';
import { makeStyles } from '@material-ui/core/styles';

const AudioHarvestLayout = (props) => {
  const classes = useStyles();

  return (
    <Layout className={classes.layout} {...props}
        appBar={AudioHarvestAppBar}
        menu={AudioHarvestMenu}/>
  );
}

const useStyles = makeStyles({
  layout: {
    marginTop: '25px',

    "& #main-content": {
      backgroundColor: 'unset'
    }
  },
});

export default AudioHarvestLayout;