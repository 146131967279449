import React from 'react';

import { makeStyles, Paper, Typography } from '@material-ui/core';

import { hasPermissionSessionStorage } from '../../../util/authProvider';

import { EpisodeImage } from '../../../components/podcasts/PodcastMisc';

const useStyles = makeStyles(theme => ({
  episode: {
    width: '100%',
    maxWidth: '750px',
    minWidth: '400px',
    margin: '10px',
    padding: '10px'
  },
  flexContainer: {
    display: 'flex'
  },
  episodeMetaContainer: {
    width: '100%'
  },
  episodeTitle: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#000',
    margin: '0px 0px 5px 0px'
  },
  episodeDescription: {
    margin: '0px',
    fontSize: '14px',
    width: '100%',
//    maxWidth: '600px'
  },
  audioPlayer: {
    width: '100%',
    height: '25px',
    margin: '25px 0px 0px 0px',
    outline: 'none'
  },
  episodeListContainer: {
    display: 'flex',
  },
  leftSpacer: {
    width: '100%',
    maxWidth: '40px',
  },
}));

export const PodcastPanel = ({ id, record, resource }) => {
  const classes = useStyles();

  return (
    <div className={classes.episodeListContainer}>
      <div className={classes.leftSpacer}/>
      { record.episodeInfos.length > 0 ?
        <div>
          { record.episodeInfos.map(it =>
              <Paper key={it.id} className={classes.episode} elevation={2}>
                <div className={classes.flexContainer}>
                  <EpisodeImage episode={it} podcast={record}/>
                  <div className={classes.episodeMetaContainer}>
                    <p className={classes.episodeTitle}>{(record.prefixSection && !!it.section) ? '[' + it.section + '] ' + it.title : it.title}</p>
                    <p className={classes.episodeDescription}>{it.description}</p>
                  </div>
                </div>
                <AudioPlayer record={it} source="enclosure"/>
              </Paper>) }
          </div>
        :
          <div>
            <Typography
                variant="p"
                color="inherit"
                className={classes.paragraph}
                id="no-episodes">
              No episodes yet!
            </Typography>
          </div>
        }
    </div>);
}

const AudioPlayer = ({ record = {}, source }) => {
  const classes = useStyles();

  return (
        <audio controls preload="metadata"
            controlsList={hasPermissionSessionStorage('DownloadPodcasts') ? '' : 'nodownload'}
            className={classes.audioPlayer}>
            <source src={record[source] + '?link=admin-ui'} type="audio/mpeg"/>
            Your browser does not support the audio element.
        </audio>);
};

