import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider, AutocompleteArrayInput } from 'react-admin';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selectorInput: {
    padding: '10px',
    margin: '0px 0px 15px 0px',
    backgroundColor: '#f0f0f0',
    display: 'inline-block'
  },
  headerText: {
    fontWeight: 'bold'
  }
}));

export const EpisodeSelectorInput = ({record, ...props}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [sections, setSections] = useState();

  const dataProvider = useDataProvider();

  useEffect(() => {
    var url = '/backend/rest/scraper/sections';
    if (record?.siteKey) {
      url += `?siteKeyHint=${record.siteKey}`;
    }
    fetch(url)
      .then(response => {
          return response.ok ? response.json() : Promise.reject(response.statusText);
      })
      .then(data => {
          return data
            .map((entry) => {
              var item = {};
              item.id = entry;
              item.name = entry;
              return item;
            });
      })
      .then((data) => {
        record.selectorSections && record.selectorSections.forEach(section => {
          if (!data.find(item => item.id === section)) {
            data.push({id: section, name: section});
          }
        });
        setSections(data);
      })
      .catch(error => {
        notify('Failed to get sections: ' + (error?.message ? error?.message : ''), { type: 'error' });
        console.log(error);
      });
  }, [dataProvider, notify, record]);

  if (!sections) {
    return null;
  }

  return (
    <Paper className={classes.selectorInput}>
      <Typography variant="inherit" className={classes.headerText}>
        Select the sections for which the articles should be added to this podcast.
        If you don't select any sections, ALL articles will be added to the podcast.
      </Typography>
      <AutocompleteArrayInput label="Section selection" source="selectorSections" choices={sections} fullWidth/>
    </Paper>
  );
}