import React, { Fragment, useState, useEffect } from 'react';
import { useRefresh, useRedirect, useUpdate, useNotify, useUnselectAll, useUpdateMany,
 List, Datagrid, TextField, BulkDeleteButton, EditButton, Button, CreateButton, TopToolbar } from 'react-admin';
import { makeStyles, Typography, Paper, Switch } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import classnames from 'classnames';

import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import Empty from '../../../util/Empty';
import HelpText from '../../../util/HelpText';

import PostFilter from './PostFilter';

import { hasPermissionSessionStorage } from '../../../util/authProvider';

import ArticlePanel from '../../../components/scraped_page/ArticlePanel';

import { WordCount } from '../../util/WordCount';

import { continueButtonStyle } from '../../style/ContinueButtonStyle.js'

const CustomEmpty = ({basePath}) => {
  const title = null;
  const message = [
    <p>The platform is in the process of capturing articles based on your section selection. This process typically takes around 3 minutes to complete.</p>,
    <p>All pages that are found on your website will display here in a list.</p>,
    <p>If no articles appear here, either enable more sections, or contact us at <a href="mailto:support@audio-harvest.com">support@audio-harvest.com</a> and we can setup your account so that you can access these articles.</p>,
    <p>{hasPermissionSessionStorage('CreateScrapedPages') ? <CreateButton label="Create article" basePath={basePath} /> : null}</p>
  ];

  return <Empty title={title} message={message} hasCreate={false}/>;
}

const PublishButton = ({ incrementVersion, label, publish, selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'scraper',
        selectedIds,
        { published: publish },
        {
            onSuccess: () => {
                refresh();
                notify('Posts ' + (publish ? 'published' : 'unpublished'));
                unselectAll('scraper');
            },
            onFailure: error => {
              notify('Error: not all pages were published!', { type: 'warning' });
              refresh();
            },
        }
    );

    const updateManyWrapper = (e) => {
      updateMany(e);

      incrementVersion();
    }

    return (
      <Button
          label={label}
          disabled={loading}
          onClick={updateManyWrapper}>
        {publish ? <DoneIcon/> : <ClearIcon/>}
      </Button>
    );
};

const PostBulkActionButtons = ({incrementVersion, setHoverElement, className, resource, basePath, filterValues, selectedIds}) => (
    <Fragment>
        <PublishButton incrementVersion={incrementVersion} label="Create audio files" publish={true} selectedIds={selectedIds} />
        <PublishButton incrementVersion={incrementVersion} label="Remove audio files" publish={false} selectedIds={selectedIds} />
        <BulkDeleteButton resource={resource} basePath={basePath} filterValues={filterValues} selectedIds={selectedIds} />
    </Fragment>
);

const CreateAudiofileButton = ({ record = {}, source, wordBudget, updateHook, className }) => {
  const diff = { published: !record.published };
  const [update] = useUpdate('scraper/list', record.id, diff, record);

  const disabled = !record.published && wordBudget && (record.characters/6) > wordBudget;

  const createAudioFile = (e) => {
    if (!disabled) {
      update();

      var wordBudgetHint = wordBudget;
      if (record.published) {
        // When UNPUBLISHING a new audio file, hint the WordCount element about the removed words
        wordBudgetHint = wordBudget + (record.characters/6);
      } else {
        // When CREATING a new audio file, hint the WordCount element about the added words
        wordBudgetHint = wordBudget - (record.characters/6);
      }

      updateHook(wordBudgetHint);
    }
    e.stopPropagation();
  }

  return (
    <Button className={className} label={record.published ? "Remove audio file" : "Create audio file"}
          onClick={createAudioFile} disabled={disabled}>
      <AudiotrackIcon/>
    </Button>
  );
}

const PublishStatusField = ({ record = {}, wordBudget, updateHook }) => {
  const classes = useStyles();

  var published = record['published'] && record['audiofileUrl'] != null && record['audiofileUrl'].length > 0;
  const disabled = !published && wordBudget && (record.characters/6) > wordBudget;

  return (
    <div className={classnames(classes.publishedState, {
          [classes.published]: published,
          [classes.unpublished]: !published,
          [classes.disabled]: disabled
        })}>
      { published ? <DoneIcon/> : <ClearIcon/> }
    </div>
  );
}

export const ListScrapedPages = ({basePath, ...props}) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const [hoverElement, setHoverElement] = useState();
  const [wordBudget, setWordBudget] = useState();
  const [autoIngestStatus, setAutoIngestStatus] = useState(false);
  const [version, setVersion] = useState(0);

  const incrementVersion = (wordBudgetHint) => {
    if (wordBudgetHint) {
      setWordBudget(wordBudgetHint);
    }

    setVersion(version + 1);
  }

  const wordUsageUpdateCallback = (data) => {
    setWordBudget(data?.limit > 0 ? (parseInt(data.limit) - parseInt(data.current)) : null);
  }

  const autoIngestGetAndSet = (newStatus) => {
    if (newStatus != null) {
      fetch('/backend/rest/selfserve/auto-ingest',
              { method: 'PUT',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: newStatus
              })
        .then(response => {
          if (!response.ok) {
            notify("Could not set auto-ingest state!", { type: 'error' });
            return Promise.reject();
          }
          setAutoIngestStatus(newStatus);
          return Promise.resolve();
        });
    }
    return autoIngestStatus;
  }

  useEffect(() => {
    fetch('/backend/rest/selfserve/auto-ingest')
      .then(response => {
        if (!response.ok) {
          notify("Could not get automatic publication state!", { type: 'error' });
          return Promise.reject();
        }
        return response.json();
      })
      .then(state => {
        setAutoIngestStatus(state);
      });
  }, [notify]);

  const WordCountField = ({record, source}) => {
    return (
      <span>{Math.round(record['characters']/6)}</span>
    );
  }

  const ToolBar = (props) => (
    <TopToolbar>
      { hasPermissionSessionStorage('CreateScrapedPages') ? <CreateButton/> : null }
    </TopToolbar>
  );

  return (
    <div>
      <Typography variant="h6" className={classes.headerText}>
        Review articles below. Edit articles for audio consumption. Select articles for audio.
      </Typography>
      <List title="Captured pages"
            actions={<ToolBar/>}
            bulkActionButtons={<PostBulkActionButtons incrementVersion={incrementVersion} setHoverElement={setHoverElement} />}
            sort={{ field: 'publishTime', order: 'DESC' }} empty={<CustomEmpty/>}
            filters={<PostFilter notify={notify} setHoverElement={setHoverElement} />}
            aside={<HelperAside hoverElement={hoverElement} version={version} autoIngestGetAndSet={autoIngestGetAndSet}
                      wordUsageUpdateCallback={wordUsageUpdateCallback} classes={classes}/>}
            exporter={false} classes={classes} basePath={basePath}>
          <Datagrid rowClick={"expand"} expand={<ArticlePanel/>}>
              <TextField label="Article title" className={classes.pageTitle} source="title"
                onMouseEnter={() => setHoverElement("title")}
                onMouseLeave={() => setHoverElement()}/>
              <TextField label="Site section" className={classes.section} source="section"
                onMouseEnter={() => setHoverElement("section")}
                onMouseLeave={() => setHoverElement()}/>
              <WordCountField label="Words"/>
              <EditButton label="Edit article" className={classes.tableButton}
                onMouseEnter={() => setHoverElement("editButton")}
                onMouseLeave={() => setHoverElement()}/>
              <CreateAudiofileButton label="Click to create audio file" source="published"
                  wordBudget={wordBudget} updateHook={incrementVersion}
                  className={classes.tableButton}
                onMouseEnter={() => { setHoverElement("audiofileButton"); }}
                onMouseLeave={() => setHoverElement()}/>
              <PublishStatusField label="Audio file status" wordBudget={wordBudget}
                onMouseEnter={() => setHoverElement("published")}
                onMouseLeave={() => setHoverElement()}/>
          </Datagrid>
      </List>
      <div>
        <MuiButton
          className={classes.continueButton}
          onClick={() => redirect("/selfserve/podcast")}>
          Continue ...
        </MuiButton>
        <Paper className={classes.lowerPaper}>
          <AutoIngestToggle autoIngestGetAndSet={autoIngestGetAndSet}/>
        </Paper>
      </div>
    </div>);
}

const AutoIngestToggle = ({autoIngestGetAndSet, extraMessage}) => {
  return (
    <Fragment>
      <span>Automatically add new articles to the podcast: </span>
      <Switch
        checked={autoIngestGetAndSet()}
        onChange={(e) => autoIngestGetAndSet(e.target.checked)}
        name="autoIngest" color="primary"
      />
      { extraMessage ? <p>{extraMessage}</p> : null }
    </Fragment>
  );
}

const HelperAside = ({hoverElement, version, autoIngestGetAndSet, wordUsageUpdateCallback, classes}) => {

  const texts = new Map();
  texts.set('default',
    [ 'This page shows you all the articles we found on your website.',
      'If you click on the row of one of the articles, the article will fold open and you can inspect the article contents.',
      'You can also select articles, and choose to publish or unpublish them. You can also choose to entirely delete irrelevant articles.',
      'Hover over the UI to get some more information about this page!']);
  texts.set('title',
    ['The title of the article.']);
  texts.set('section',
    ['The name of the section where the article was found.']);
  texts.set('editButton',
    ['Pressing this button will take you to a page where you can edit the article.']);
  texts.set('audiofileButton',
    ['Pressing this button will create an audio file for the article.',
      'You can also select multiple articles and press the \'Publish\' button that will appear above the list.',
      'If an audio file already exists for the article, pressing the button will remove the audio file and any related podcast episodes.']);
  texts.set('published',
    ['This column shows if an article is published or not.',
      'A published article has an audio version created, and is available for including in a podcast.']);
  texts.set('filters',
    ['These fields allow you to filter the articles.',
      'Selecting a value in any of these filters will only show articles that match that value.',
      'The first field is a search field, it will show any articles where the title matches the entered text.']);

  return (
    <div>
      <Paper className={classes.asidePaper}>
        <WordCount update={version} updateCallback={wordUsageUpdateCallback}/>
      </Paper>
      <Paper className={classes.asidePaper}>
        <AutoIngestToggle autoIngestGetAndSet={autoIngestGetAndSet} extraMessage="If this is turned OFF, you will need to explicitly create audio files for any articles you want to publish in the podcast."/>
      </Paper>
      <HelpText title="Articles page" activeText={hoverElement} texts={texts}/>
    </div>
  );
}

const useStyles = makeStyles({
  pageTitle: {
    fontStyle: 'italic',
  },
  content: {
    marginBottom: 'auto'
  },
  section: {
  },
  publishedState: {
    width: '30px',
    padding: '5px 3px 0px 3px',
    textAlign: 'center',
    borderRadius: '5px',
  },
  published: {
    color: 'green',
    backgroundColor: '#00ff0020'
  },
  unpublished: {
    color: 'red',
    backgroundColor: '#ff000020'
  },
  disabled: {
    color: 'grey',
    backgroundColor: '#aaaaaa20'
  },
  headerText: {
    margin: '20px 0px 10px',
  },
  asidePaper: {
    width: '300px',
    height: 'fit-content',
    margin: '0px 20px 20px',
    padding: '20px'
  },
  lowerPaper:{
    display: 'inline-block',
    height: 'fit-content',
    margin: '20px 20px',
    padding: '20px'
  },
  tableButton: {
    backgroundColor: '#00000000',
    minWidth: '100px',
    textAlign: 'left',

    "&:hover": {
      backgroundColor: 'white'
    },

    "& span": {
      color: '#0FD1B0',
    }
  },
  continueButton: continueButtonStyle,
  createButton: {
    padding: '5px',
    alignSelf: 'center',
    margin: '5px 20px'
  }
});

export default ListScrapedPages;
