import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

export const PublishedIcons = ({ record = {}, source, basePath, ...props}) => {
  const classes = useStyles();

  return (
    <div label="Published" className={classes.publishedContainer} {...props}>
      <a href={record.appleUrl} onClick={(e) => e.stopPropagation()}>
        <img className={classnames(classes.publishedIcon, classes.appleIcon, {
                                       [classes.activeIcon]: record.appleState === 'PUBLISHED',
                                       [classes.disabledIcon]: record.appleState !== 'PUBLISHED',
                                   })} src="/assets/apple-podcast.svg"
                                   alt="Apple Logo"/>
      </a>
      <a href={record.googleUrl} onClick={(e) => e.stopPropagation()}>
        <img className={classnames(classes.publishedIcon, classes.googleIcon, {
                                       [classes.activeIcon]: record.googleState === 'PUBLISHED',
                                       [classes.disabledIcon]: record.googleState !== 'PUBLISHED',
                                       })} src="/assets/google.png"
                                   alt="Google Logo"/>
      </a>
      <a href={record.spotifyUrl} onClick={(e) => e.stopPropagation()}>
        <img className={classnames(classes.publishedIcon, classes.spotifyIcon, {
                                       [classes.activeIcon]: record.spotifyState === 'PUBLISHED',
                                       [classes.disabledIcon]: record.spotifyState !== 'PUBLISHED',
                                       })} src="/assets/spotify.svg"
                                   alt="Spotify Logo"/>
      </a>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  publishedContainer: {
    width: '100px',
    display: 'inline-block',
    margin: '10px'
  },
  publishedIcon: {
    width: '20px',
    height: '20px',
    verticalAlign: 'top'
  },
  appleIcon: {
    width: '20px',
    height: '20px',
    margin: '0px 2px 0px 2px'
  },
  googleIcon: {
    width: '25px',
    height: '25px',
    margin: '-3px 2px 0px 2px'
  },
  spotifyIcon: {
    width: '30px',
    height: '30px',
    margin: '-5px -4px 0px -4px'
  },
  activeIcon: {
    filter: 'grayscale(0)'
  },
  disabledIcon: {
    filter: 'grayscale(1)'
  }
}));