import React from 'react';
import { TextInput, SimpleForm, Create } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';

import { SectionSelectorInput } from '../../../util/SectionSelectorInput'
import { validateTitle, validateDescription, validateSection, validateFileUpload } from '../../../util/ArticleValidation'
import { AudioFileUploadInput } from './AudioFileUploadInput'

export const CreateAudioPage = props => {
  const classes = useStyles();

  return (
    <div className={classes.centerContainer}>
      <Typography variant="h6" className={classes.headerText}>
        Create a new audio article by filling in the form below. Select an audio file to upload.
      </Typography>
      <Create title="New article" {...props} className={classes.createForm}>
          <SimpleForm redirect="list">
              <TextInput source="title" multiline fullWidth validate={validateTitle}/>
              <TextInput source="description" multiline fullWidth validate={validateDescription}/>
              <TextInput source="author" fullWidth/>
              <SectionSelectorInput fullWidth validate={validateSection}/>
              <AudioFileUploadInput validate={validateFileUpload}/>
          </SimpleForm>
      </Create>
    </div>);
}

const useStyles = makeStyles(theme => ({
  centerContainer: {
    margin: 'auto',
    minWidth: '400px',
    maxWidth: '700px',
    width: '100%'
  },
  headerText: {
    maxWidth: '600px'
  },
  spacer: {
    margin: 'auto'
  },
  createForm: {
    width: '100%',
    maxWidth: '600px',
    marginRight: 'auto'
  },
}));
