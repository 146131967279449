import React from 'react';
import { useNotify, useRedirect, required, minLength, maxLength, AutocompleteArrayInput, Edit, Create, SimpleForm,
    TextInput, SelectInput } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';

import { hasPermissionSessionStorage } from '../../../util/authProvider';

import { podcastCategoryChoices, explicitnessChoices } from '../../../util/PodcastUtil';
import { PodcastLogoInput } from '../../../components/podcasts/PodcastLogoInput';
import { EpisodeSelectorInput } from '../../../components/podcasts/EpisodeSelectorInput';

import { mainContainerStyle } from '../../style/mainContainerStyle';

const ExplicitnessChoice = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.explicitnessContainer}>
      <SelectInput label="Explicit" source="explicitness"
        validate={required()}
        defaultValue={"NO"}
        choices={explicitnessChoices}
        className={classes.explicitnessInput}/>
      <div className={classes.explicitnessInfo}>
        <ul>
          <li><b>Yes:</b> The podcast contains explicit language</li>
          <li><b>No:</b> The podcast does not contain explicit language</li>
          <li><b>Cleaned:</b> The podcast contains explicit language that has been cleaned out (eg. silenced or beeped)</li>
        </ul>
      </div>
    </div>
  );
}

export const PodcastEdit = ({basePath, resource, id, ...props}) => {
  const classes = useStyles();
  const mainContainerClass = mainContainerStyle();

  return (
    <div className={mainContainerClass.mainContainer}>
      <Typography variant="h6" className={classes.headerText}>Edit your podcast information below.</Typography>
      <Edit mutationMode="pessimistic" title="Edit podcast" basePath={basePath} resource={resource} id={id} className={classes.podcastCreateEdit}>
          <SimpleForm >
              <PodcastLogoInput/>
              <TextInput source="title" fullWidth validate={[required(), minLength(5), maxLength(250)]}/>
              <TextInput source="description" fullWidth multiline validate={[required(), minLength(5), maxLength(2000)]}/>
              <ExplicitnessChoice/>
              <AutocompleteArrayInput label="Categories" source="categories" fullWidth choices={podcastCategoryChoices} />
              { hasPermissionSessionStorage('MultiplePodcasts') ? <EpisodeSelectorInput/> : null }
          </SimpleForm>
      </Edit>
    </div>
  );
}

export const PodcastCreate = ({basePath, resource, ...props}) => {
  const classes = useStyles();
  const mainContainerClass = mainContainerStyle();

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Podcast created " + basePath);
    redirect(basePath);
  }

  return (
    <div className={mainContainerClass.mainContainer}>
      <Typography variant="h6" className={classes.headerText}>Create your podcast by uploading a brand logo and by inserting a podcast title and description below.</Typography>
      <Create title="Create podcast" onSuccess={onSuccess} basePath={basePath} resource={resource} className={classes.podcastCreateEdit}>
          <SimpleForm>
              <PodcastLogoInput/>
              <TextInput source="title" fullWidth validate={[required(), minLength(5), maxLength(250)]}/>
              <TextInput source="description" fullWidth multiline validate={[required(), minLength(5), maxLength(2000)]}/>
              <ExplicitnessChoice/>
              <AutocompleteArrayInput label="Categories" source="categories" fullWidth
                  choices={podcastCategoryChoices}/>
              { hasPermissionSessionStorage('MultiplePodcasts') ? <EpisodeSelectorInput/> : null }
          </SimpleForm>
      </Create>
      <Typography variant="h6" className={classes.footerText}>Please be aware that you must own the copyright to all content that you distribute as a brand podcast via our distribution platform or have legal permission from the copyrighter holder to distribute the content as part of your branded podcast.</Typography>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  selectorInput: {
    padding: '10px',
    backgroundColor: '#f0f0f0',
    display: 'inline-block'
  },
  podcastCreateEdit: {
    width: '100%',
    maxWidth: '600px',
    margin: '0px auto'
  },
  spacer: {
    margin: 'auto'
  },
  explicitnessContainer: {
    display: 'inline-flex'
  },
  explicitnessInfo: {
    fontSize: '80%',

    '& ul': {
      margin: '8px 0px 0px'
    }
  },
  pageTitle: {
    fontStyle: 'italic',
  },
  headerText: {
    margin: '20px 0px 10px',
  },
  footerText: {
    margin: '20px',
    fontSize: '14px',
    fontStyle: 'italic'
  }
}));