import React, { useState, useEffect, useRef } from 'react';

import { useNotify, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

export const CrawlStatus = () => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const dateTimeFormatter = new Intl.DateTimeFormat("en-GB", {
                                      day: 'numeric', month: 'long',
                                      hour: 'numeric', minute: 'numeric'
                                   });

  const crawlerStatus = useRef();
  const [dummy, setDummy] = useState();

  useEffect(() => {
    fetch('/backend/rest/selfserve/crawler-status')
      .then(response => {
        if (!response.ok) {
          if (response.status === 403) { // When permission denied -> log back in
            window.location = '/';
          }

          return Promise.reject("Could not get crawler status!");
        } else if (response.status === 204) {
          // Nothing here
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (crawlerStatus.current != null && JSON.stringify(crawlerStatus.current) !== JSON.stringify(json)
            && window.location.href.endsWith("scraper/list")) {
          console.log("Triggering refresh...");
          refresh();
        };
        crawlerStatus.current = json;
      })
      .catch(error => console.log(error));
    var currentTimeoutId = setTimeout(() => {setDummy(new Date())}, 10000);

    return function () {
      clearTimeout(currentTimeoutId)
    }
  }, [dummy, refresh]);

  if (!crawlerStatus?.current) {
    return <div className={classes.statusContainer}></div>;
  }

  const triggerCrawl = () => {
    fetch('/backend/rest/selfserve/triggerCrawl')
      .then(response => {
        if (!response.ok) {
          notify("Could not trigger crawl!", { type: 'error' });
          return Promise.reject();
        }
        return response.text();
      })
      .then(ignored => notify("Article refresh triggered!"));
  }

  var scheduledTime = crawlerStatus.current?.scheduledTime ? new Date(crawlerStatus.current.scheduledTime) : null;
  var lastCrawlTime = crawlerStatus.current?.lastCrawlTime ? new Date(crawlerStatus.current.lastCrawlTime) : null;

  if (lastCrawlTime) {
    if (scheduledTime && scheduledTime > lastCrawlTime) {
      return (
        <div className={classnames(classes.statusContainer, classes.running)}>
          Update running!<br/>Started: {new Intl.DateTimeFormat("en-GB", {
                                                  day: 'numeric', month: 'long',
                                                  hour: 'numeric', minute: 'numeric'
                                        }).format(scheduledTime)}
        </div>
      );
    } else {
      return (
        <div onClick={triggerCrawl} className={classnames(classes.statusContainer, classes.updated)}>
          Last updated:<br/>{dateTimeFormatter.format(lastCrawlTime)}
        </div>
      );
    }
  } else {
    return (
      <div className={classnames(classes.statusContainer, classes.running)}>
        Update running!
      </div>
    );
  }
}

const useStyles = makeStyles(theme => ({
  statusContainer: {
  },
  updated: {
    cursor: 'pointer'
  },
  running: {
    color: '#FFFF00',
    borderRadius: '20px',
    cursor: 'default'
  }
}));