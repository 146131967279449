import React, { Fragment } from 'react';
import { useRefresh, useRedirect, useUpdate, useNotify, useUnselectAll, useUpdateMany,
 List, Datagrid, TextField, BulkDeleteButton, EditButton, Button, CreateButton, TopToolbar } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import classnames from 'classnames';

import Empty from '../../../util/Empty';

import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import PostFilter from '../scraped_page/PostFilter';

import { hasPermissionSessionStorage } from '../../../util/authProvider';

import ArticlePanel from '../../../components/scraped_page/ArticlePanel';

import { continueButtonStyle } from '../../style/ContinueButtonStyle.js'

const CustomEmpty = (props) => {
  const title = null;
  const message = [
    <p>No audio articles created!</p>,
    <p>Press the create button to create an audio article.</p>,
    <p><CreateButton label="Create audio article" {...props} /></p>
  ];

  return <Empty title={title} message={message} hasCreate={false}/>;
}

const PostBulkActionButtons = (props) => (
    <Fragment>
        <PublishButton label="Publish audio files" publish={true} {...props} />
        <PublishButton label="Unpublish audio files" publish={false} {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

const PublishButton = ({ label, publish, selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'scraper',
        selectedIds,
        { published: publish },
        {
            onSuccess: () => {
                refresh();
                notify('Posts ' + (publish ? 'published' : 'unpublished'));
                unselectAll('scraper');
            },
            onFailure: error => {
              notify('Error: not all pages were published!', { type: 'warning' });
              refresh();
            },
        }
    );

    const updateManyWrapper = (e) => {
      updateMany(e);
    }

    return (
      <Button
          label={label}
          disabled={loading}
          onClick={updateManyWrapper}>
        {publish ? <DoneIcon/> : <ClearIcon/>}
      </Button>
    );
};

const PublishAudiofileButton = ({ record = {}, source, ...props}) => {
  const diff = { published: !record.published };
  const [update, {loading}] = useUpdate('scraper/audio-articles', record.id, diff, record);

  const publishAudioFile = (e) => {
    update();
    e.stopPropagation();
  }

  return (
    <Button {...props} label={record.published ? "Unpublish audio article" : "Publish audio article"}
          onClick={publishAudioFile} disabled={loading}>
      <AudiotrackIcon/>
    </Button>
  );
}

const PublishStatusField = ({ record = {}, source }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.publishedState, {
          [classes.published]: record[source],
          [classes.unpublished]: !record[source]
        })}>
      { record[source] ? <DoneIcon/> : <ClearIcon/> }
    </div>
  );
}

export const ListAudioPages = ({basePath, ...props}) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const ToolBar = (props) => (
    <TopToolbar>
      { hasPermissionSessionStorage('CreateScrapedPages') && false ? <CreateButton/> : null }
    </TopToolbar>
  );

  return (
    <div>
      <Typography variant="h6" className={classes.headerText}>
        Review articles below. Edit articles for audio consumption. Select articles for audio.
      </Typography>
      <List title="Captured pages"
            actions={<ToolBar/>}
            bulkActionButtons={<PostBulkActionButtons />}
            sort={{ field: 'publishTime', order: 'DESC' }} empty={<CustomEmpty/>}
            filters={<PostFilter setHoverElement={() => {;}} notify={notify} className={classes.postFilter} />}
            exporter={null} classes={classes} basePath={basePath}>
          <Datagrid rowClick={"expand"} expand={<ArticlePanel/>}>
              <TextField label="Article title" className={classes.pageTitle} source="title"/>
              <TextField label="Site section" className={classes.section} source="section"/>
              <EditButton label="Edit" className={classes.tableButton}/>
              <PublishAudiofileButton label="Click to publish audio file" source="published"
                  className={classes.tableButton}/>
              <PublishStatusField label="Audio file status" source="published"/>
          </Datagrid>
      </List>
      <div>
        <MuiButton
          className={classes.continueButton}
          onClick={() => redirect("/selfserve/podcast")}>
          Continue ...
        </MuiButton>
      </div>
    </div>);
}

const useStyles = makeStyles({
  pageTitle: {
    fontStyle: 'italic',
  },
  content: {
    margin: '20px 20px auto',
  },
  postFilter: {
    margin: '0px 20px',
  },
  section: {
  },
  headerText: {
    margin: '20px 20px 10px',
  },
  lowerPaper:{
    display: 'inline-block',
    height: 'fit-content',
    margin: '20px 20px',
    padding: '20px'
  },
  tableButton: {
    backgroundColor: '#00000000',
    minWidth: '100px',
    textAlign: 'left',

    "&:hover": {
      backgroundColor: 'white'
    },

    "& span": {
      color: '#0FD1B0',
    }
  },
  continueButton: continueButtonStyle,
  createButton: {
    padding: '5px',
    alignSelf: 'center',
    margin: '5px 20px'
  },
  publishedState: {
    width: '30px',
    padding: '5px 3px 0px 3px',
    textAlign: 'center',
    borderRadius: '5px',
  },
  published: {
    color: 'green',
    backgroundColor: '#00ff0020'
  },
  unpublished: {
    color: 'red',
    backgroundColor: '#ff000020'
  },
});
