import React, {useState, useEffect} from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { defaults, Doughnut } from 'react-chartjs-2';

export const DoughnutGraph = ({title, endpoint, period, lastNum, ...rest}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [data, setData] = useState();
  const [rawData, setRawData] = useState();

  useEffect(() => {
    var url = '/backend/rest/report/' + endpoint + '?lastNum=' + lastNum + (period ? '&period=' + period : '');
    const request = new Request(url, {
        method: 'GET'
    });
    fetch(request)
      .then(response => {
          return response.ok ? response.json() : Promise.reject(response.statusText);
      })
      .then(json => {
        setRawData(json);

        setData({
          labels: ['Views', 'Plays'],
          datasets: [
            {
              label: 'Views',
              data: [json.views > 0 ? json.views : 1, 0, 0],
              backgroundColor: [
                backgroundColors[0],
                backgroundColors[1],
                backgroundColors[2]
              ],
              rotation: 235,
              circumference: 250,
              cutout: '80%',
              borderRadius: 50
            },
            {
              label: 'Plays',
              data: [0, json.plays, (json.views - json.plays) > 0 ? (json.views - json.plays) : 1],
              backgroundColor: [
                backgroundColors[0],
                backgroundColors[1],
                backgroundColors[2]
              ],
              rotation: 235,
              circumference: 250,
              cutout: '80%',
              borderRadius: 50
            }
          ]
        });
      })
      .catch(error => {
        notify('Failed to fetch report data! ' + (error?.message ? error?.message : ''), { type: 'error' });
        console.log(error);
      });
  }, [notify, period, lastNum, endpoint]);

  return (
    <div {...rest}>
      <Paper className={classes.graphPaper}>
        <Typography variant="h5" className={classes.headerText}>{title}</Typography>
        <div className={classes.centerDiv}>
          <Typography variant="h4" className={classes.doughnutNumber}>{rawData ? new Intl.NumberFormat('en-US').format(rawData?.plays) : '-'}</Typography>
          <Typography variant="h4" className={classes.downloadsText}>Plays</Typography>
          <Typography variant="span" className={classes.viewsNumber}>{rawData ? new Intl.NumberFormat('en-US').format(rawData?.views) : '-'}</Typography>
          <Typography variant="span" className={classes.viewsText}> views</Typography>
        </div>
        <Doughnut data={data} options={options} />
      </Paper>
    </div>
  );
}

defaults.font.family = "'Roboto', 'Helvetica', 'Arial', sans-serif";
defaults.plugins.legend.position = 'bottom';
defaults.elements.line.cubicInterpolationMode = 'default';
defaults.elements.line.tension = '0.4';
const options = {
  plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 16,
              }
          },
      },
  },
  elements: {
    bar: {
      borderRadius: 100
    }
  },
};

const useStyles = makeStyles(theme => ({
  graphPaper: {
    margin: '10px',
    padding: '20px',
    position: 'relative'
  },
  headerText: {
    textAlign: 'center'
  },
  doughnutNumber: {
    color: '#0FD1B0',
    fontWeight: 'bold',
    fontSize: '40px'
  },
  downloadsText: {
    color: '#aaaaaa',
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: '40px'
  },
  viewsNumber: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  viewsText: {
    color: '#aaaaaa',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  centerDiv: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }
}));

const backgroundColors = [
  "#00483C",
  "#0FD1B0",
  "#DDDDDD",
  "#04BA9B",
];

export default DoughnutGraph;
