import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Login } from 'react-admin';

const loginStyles = makeStyles({
    loginBackgroundImage: {
      filter: 'invert',
      backgroundPosition: 'center',
      backgroundImage: 'linear-gradient(0deg, rgba(240, 255, 252, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(240, 255, 252, 1) 100%)',

      "&>div.MuiPaper-root>div": {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor: 'transparent',
        backgroundImage: 'url(/ah_login_logo.png)',
        height: '64px',
        margin: '10px 10px 0px',

        "& .MuiAvatar-root": {
          display: 'none'
        }
      }
    },
});

export const AHLoginPage = ({theme}) => {
    const classes = loginStyles();

    return (
        <Login className={classes.loginBackgroundImage} theme={theme}/>
    );
}