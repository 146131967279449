import { makeStyles } from '@material-ui/core';

export const mainContainerStyle = makeStyles(theme => ({
  mainContainer: {
    width: 'fit-content',
    margin: '20px auto',
    minWidth: '600px',

    "& > h6": {
      textAlign: 'left'
    }
  },
}));