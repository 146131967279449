import React, { useState, useEffect } from 'react';
import { Loading, useNotify, Title } from 'react-admin';

import { makeStyles, Typography, Paper, TableContainer, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import { mainContainerStyle } from '../style/mainContainerStyle';

import { SubscriptionOverview } from './payment/SubscriptionOverview';
import { PaymentOnboarding } from './payment/PaymentOnboarding';

import { hasRole } from '../../util/authProvider';

import Empty from '../../util/Empty';

// Setup Stripe.js and the Elements provider
import {
  Elements
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

export const PaymentChange = props => {
  const classes = useStyles();
  const notify = useNotify();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [stripePromise, setStripePromise] = useState();
  const [customerInfo, setCustomerInfo] = useState();

  useEffect(() => {
    fetch('/backend/rest/payment/subscription/config')
      .then(response => {
        if (!response.ok) {
          console.log("Could not fetch subscription config!");
          return Promise.reject();
        }
        return response.json();
      })
      .then(json => setStripePromise(loadStripe(json.publishableKey)))
      .catch(error => notify('Could not load Stripe!', { type: 'error' }));
  }, [notify]);

  useEffect(() => {
    if (!customerInfo) {
      fetch('/backend/rest/payment/subscription/details', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (!response.ok) {
          console.log("Could not fetch subscription details!");
          return Promise.reject();
        } else if (response.status === 204) {
          console.log("No subscription exists yet!");
          return null;
        }
        return response.json();
      })
      .then(response => {
        if (!response?.customer) {
          response.customer = {};
        }

        setCustomerInfo(response);
        setDataLoaded(true);
      })
      .catch(error => {
        console.log("Error when fetching subscription details: " + error);
      });
    }
  }, [notify, customerInfo]);

  if (!dataLoaded || !stripePromise) {
    return (<Loading loadingPrimary="Loading subscription status" loadingSecondary="Please wait..." />);
  }

  return (
    <Elements stripe={stripePromise} className={classes.mainContainer}>
      <PaymentOnboarding customerInfo={customerInfo} setCustomerInfo={setCustomerInfo}/>
    </Elements>
  );
}

export const PaymentOverview = props => {
  const classes = useStyles();
  const mainContainerClasses = mainContainerStyle();
  const notify = useNotify();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [stripePromise, setStripePromise] = useState();
  const [customerInfo, setCustomerInfo] = useState();

  useEffect(() => {
    fetch('/backend/rest/payment/subscription/config')
      .then(response => {
        if (!response.ok) {
          console.log("Could not fetch subscription config!");
          return Promise.reject();
        }
        return response.json();
      })
      .then(json => setStripePromise(loadStripe(json.publishableKey)))
      .catch(error => notify('Could not load Stripe!', { type: 'error' }));
  }, [notify]);

  useEffect(() => {
    if (!customerInfo) {
      fetch('/backend/rest/payment/subscription/details', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (!response.ok) {
          console.log("Could not fetch subscription details!");
          return Promise.reject();
        } else if (response.status === 204) {
          console.log("No subscription exists yet!");
          return null;
        }
        return response.json();
      })
      .then(response => {
        if (!response?.customer) {
          response.customer = {};
        }
        setCustomerInfo(response);
        setDataLoaded(true);
      })
      .catch(error => {
        console.log("Error when fetching subscription details: " + error);
      });
    }
  }, [notify, customerInfo]);

  if (!dataLoaded || !stripePromise) {
    return (<Loading loadingPrimary="Loading subscription status" loadingSecondary="Please wait..." />);
  }

  if (customerInfo?.customer?.subscriptions?.data[0]) {
    return (
      <div className={mainContainerClasses.mainContainer}>
        <Elements stripe={stripePromise} className={classes.mainContainer}>
          <Title title="Subscription overview" />
          <SubscriptionOverview customerInfo={customerInfo} />
        </Elements>
      </div>
    );
  } else if (hasRole('PITCHGROUND_A')) {
    return (
      <>
        <Title title="Subscription overview" />
        <PitchGroundSubscription
          planName="PitchGround plan A (lifetime deal)"
          wordBudget="5000 words / month"
          features={[
            "Automatically Create Audio Files and Distribute Podcasts Across iTunes, Spotify, & Google Play",
            "Unlimited Podcast Episodes",
            "Embed Audio Player On Client Website - Enables Visitors To Listen To Articles",
            "New and Existing Customers on iTunes, Spotify etc.",
            "Reporting Analytics",
            "Podcast Hosting Management For Podcasts Generated by the AudioHarvest platform"
          ]}/>
      </>
    );
  } else if (hasRole('PITCHGROUND_B')) {
    return (
      <>
        <Title title="Subscription overview" />
        <PitchGroundSubscription
          planName="PitchGround plan B (lifetime deal)"
          wordBudget="12000 words / month"
          features={[
            "Automatically Create Audio Files and Distribute Podcasts Across iTunes, Spotify, & Google Play",
            "Unlimited Podcast Episodes",
            "4 Unique Podcast Series",
            "Add Audio Marketing Messages To The Podcast",
            "Embed Audio Player On Client Website - Enables Visitors To Listen To Articles",
            "New and Existing Customers on iTunes, Spotify etc.",
            "Reporting Analytics",
            "Podcast Hosting Management For Podcasts Generated by the AudioHarvest platform"
          ]}/>
      </>
    );
  } else if (hasRole('PITCHGROUND_C')) {
    return (
      <>
        <Title title="Subscription overview" />
        <PitchGroundSubscription
          planName="Enterprise"
          wordBudget="30000 words / month"
          features={[
            "Automatically Create Audio Files and Distribute Podcasts Across iTunes, Spotify, & Google Play",
            "Unlimited Podcast Episodes",
            "Unlimited Podcast Series",
            "Add Audio Marketing Messages To The Podcast",
            "Embed Audio Player On Client Website - Enables Visitors To Listen To Articles",
            "New and Existing Customers on iTunes, Spotify etc.",
            "Reporting Analytics",
            "Podcast Hosting Management For Podcasts Generated by the AudioHarvest platform"
          ]}/>
      </>
    );
  } else {
    return (
      <>
        <Title title="Subscription overview" />
        <Empty title="No active subscription!" message={['Create a subscription first!']}/>
      </>
    );
  }
}

const useStyles = makeStyles(theme => ({
  paragraphText: {
    textAlign: 'center',
    margin: '100px auto 20px'
  },
  title: {
    fontSize: '2em',
    fontWeight: '600',
    margin: '20px 0px 10px',
    textAlign: 'center'
  },
  tableContainer: {
    maxWidth: '600px',
    margin: '20px 0px',

    '& th': {
      fontWeight: '600'
    }
  },
  paper: {
    marginTop: '20px',
    padding: '15px',
    maxWidth: '800px',
    margin: 'auto'
  },
  planName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '1.2em',
    color: 'green'
  },
  wordBudget: {
    fontWeight: 'bold'
  },
  features: {
    textAlign: 'left'
  },
  featureItem: {
    margin: '10px 0px'
  }
}));

const PitchGroundSubscription = ({planName, wordBudget, features}) => {
  const classes = useStyles();

  return (
   <Paper className={classes.paper}>
      <Typography
          variant="h3"
          color="inherit"
          className={classes.title}
          id="subscription">
        Your subscription
      </Typography>
      <div className={classes.tableContainer}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow key="subscription-status">
                <TableCell component="th" scope="row">Subscription name</TableCell>
                <TableCell align="right">
                  <span className={classes.planName}>
                    {planName}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow key="word-budget">
                <TableCell component="th" scope="row">Word budget</TableCell>
                <TableCell align="right">
                  <span className={classes.wordBudget}>
                    {wordBudget}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow key="features">
                <TableCell component="th" scope="row">Features</TableCell>
                <TableCell align="right">
                  <span className={classes.features}>
                    <ul>
                      { features.map(f => <li className={classes.featureItem}>{f}</li>) }
                    </ul>
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>);
}

