import React, { useEffect, useState, Fragment } from 'react';
import { hasPermissionSessionStorage } from '../../../util/authProvider';

import { makeStyles, Typography, TextField, FormControl, Radio, RadioGroup, FormControlLabel, Paper } from '@material-ui/core';

import PaymentTierOverview from './PaymentTierOverview';

export const CustomerDetailsStep = ({onCustomerDetailsChange, customerDetailsError, customerInfo, usage}) => {
  const classes = useStyles();

  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [hideInputFields, setHideInputFields] = useState(false);

  useEffect(() => {
    fetch('/backend/rest/payment/subscription/config')
      .then(response => {
        if (!response.ok) {
          console.log("Could not fetch subscription config!");
          return Promise.reject();
        }
        return response.json();
      })
      .then(json => {
        setSubscriptionTypes(Object.values(json.subscriptionTypes));
      })
      .then(ignored => onCustomerDetailsChange(customerInfo));
  }, [customerInfo, onCustomerDetailsChange]);

  const setName = (name) => {
    customerInfo.customer.name = name;
  }

  const setEmail = (email) => {
    customerInfo.customer.email = email;
  }

  const setSelectedSubscription = (subscriptionType) => {
    customerInfo.subscriptionType = subscriptionType;
    var subscriptionInfo = subscriptionTypes.filter(st => st.name === subscriptionType)[0];
    if (!customerInfo?.priceDetails && subscriptionInfo) {
      customerInfo.priceDetails = { currency: subscriptionInfo.currency };
    }
    setHideInputFields(subscriptionInfo ? false : true);
  }

  const createSubscriptionLabel = (subscriptionType, currentSubscription) => {
    return (
      <div className={classes.subscriptionTypeLabel}>
        <h3>{subscriptionType.displayName} - {formatPrice(subscriptionType.unitAmount, subscriptionType.currency)}
          {currentSubscription ? <span> (current subscription)</span> : null}
        </h3>
        <p>{subscriptionType.description}</p>
      </div>
    );
  }

  const formatPrice = (decimalAmount, currency) => {
    if (decimalAmount === 0) {
      return 'FREE';
    } else {
      var amount = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(decimalAmount/100);
      return amount + ' / month';
    }
  }

  const subscriptionTypeToRadioOption = st => {
    const isFree = st.unitAmount === 0;
    if (hasPermissionSessionStorage('AppSumo') && isFree) {
      return null;
    }

    const isCurrentSubscription = customerInfo?.priceDetails?.priceId ? st.priceId === customerInfo?.priceDetails?.priceId : st.name === 'free-trial';
    const label = createSubscriptionLabel(st, isCurrentSubscription);

    return (
      <Paper key={st.name} className={classes.subscriptionTypePaper}>
        <FormControlLabel value={st.name} control={<Radio color="primary" />} label={label} disabled={isCurrentSubscription}/>
      </Paper>
    );
  }

  return (
    <div className={classes.mainContainer}>
      { customerInfo && customerInfo?.priceDetails?.tiers
            ? <PaymentTierOverview tiers={customerInfo.priceDetails.tiers}
                                    currency={customerInfo.priceDetails.currency}
                                    usage={usage}/>
            :
            <div>
              <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.pickSubscriptionTitle}
                  id="subscription-select">
                Select your subscription:
              </Typography>
              <FormControl component="fieldset" className={classes.subscriptionTypesContainer}>
                <RadioGroup aria-label="subscription-type" name="subscription" onChange={(e) => { setSelectedSubscription(e.target.value); onCustomerDetailsChange(customerInfo); }}>
                  { subscriptionTypes.map(subscriptionTypeToRadioOption) }
                  <Paper key="enterprise" className={classes.subscriptionTypePaper}>
                    <FormControlLabel value={"enterprise"} control={<Radio color="primary" />}
                      label={
                          <div className={classes.subscriptionTypeLabel}>
                            <h3>Enterprise - contact us</h3>
                            <p>Mail us at <a href="mailto:info@audio-harvest.com">info@audio-harvest.com</a> to get a custom quote</p>
                          </div>
                      }/>
                  </Paper>
                </RadioGroup>
              </FormControl>
            </div>
      }
      { !hideInputFields
        ? <Fragment>
            <Typography
                variant="h5"
                color="inherit"
                className={classes.companyDetailsTitle}
                id="email-text">
              Please enter or verify your company details:
            </Typography>
            <FormControl className={classes.input} fullWidth={false}>
              <TextField id="name-input" label="Business Name" variant="filled"
                autoFocus={true} required={true} defaultValue={customerInfo?.customer?.name}
                onChange={(e) => { setName(e.target.value); onCustomerDetailsChange(customerInfo); }}/>
            </FormControl>
            <FormControl className={classes.input} fullWidth={false}>
              <TextField id="email-input" label="Email" variant="filled"
                required={true} defaultValue={customerInfo?.customer?.email}
                onChange={(e) => { setEmail(e.target.value); onCustomerDetailsChange(customerInfo); }}/>
            </FormControl>
            <div>
              <span className={classes.customerDetailsError}
                    visibility={customerDetailsError ? "visible" : "hidden"}>
                  {customerDetailsError}
              </span>
            </div>
          </Fragment> : null }
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    maxWidth: '600px'
  },
  companyDetailsTitle: {
    marginTop: '20px'
  },
  input: {
    width: 'calc(100% - 30px)',
    maxWidth: '500px',
    margin: '10px 0px'
  },
  vatTypeInput: {
    width: '300px'
  },
  customerDetailsError: {
    color: 'red'
  },
  subscriptionTypesContainer: {
    width: '100%',
  },
  subscriptionTypePaper: {
    margin: '10px 0px',
    padding: '15px'
  },
  subscriptionTypeLabel: {
    '& h3': {
      margin: '0px',
      textTransform: 'capitalize',
    },
    '& p': {
      margin: '0px'
    }
  }
}));