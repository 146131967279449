import React from 'react';

import { CreateButton, useListContext } from 'react-admin';

import { Typography, Box, makeStyles } from '@material-ui/core';

import Inbox from '@material-ui/icons/Inbox';

const useStyles = makeStyles(theme => ({
  emptyBox: {
    margin: '0px 20px',
    opacity: '0.5',
  },
  icon: {
    width: '9em',
    height: '9em',
  },
  message: {
    margin: '30px auto',
    maxWidth: '600px',
  },
  paragraph: {
    fontSize: '120%',
  }
}));

const Empty = ({title, message, hasCreate}) => {
    const { basePath } = useListContext();
    const classes = useStyles();

    return (
        <Box className={classes.emptyBox} textAlign="center" m={1}>
            <Inbox className={classes.icon}/>
            <Typography className={classes.title} variant="h4" paragraph>
                {title}
            </Typography>
            <div className={classes.message}>
              { message.map(paragraph =>
                <Typography className={classes.paragraph} variant="body1">
                    {paragraph}
                </Typography>)
              }
            </div>
            { hasCreate ? <CreateButton variant="contained" basePath={basePath}/> : null }
        </Box>
    );
};

export default Empty;