
export const getWordsUsed = () => {
  return fetch('/backend/rest/selfserve/usage')
    .then(response => {
      if (!response.ok) {
        console.log("Could not get usage!");
        return Promise.reject();
      }
      return response.json();
    })
    .catch(error => console.log('Failed to fetch word usage!'));
}